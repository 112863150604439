<template lang="html">


<pageTemplate :sensor="sensor">
    <template v-slot:sensor>
      <div class="svg-image water-sensor-Color">
              <soilSvg :width="'100%'" :height="200" :sensor="sensor"/>
            </div>
    </template>

    <template v-slot:info>
       <soilInfo :sensor="sensor"/>
    </template>

    <b-card>

   


      <div class="d-flex flex-wrap mb-2">
      <div>
        <h5>Histórico de umidade</h5>
      </div>
      <div class="ml-auto">
        <data-range v-model="dateRange"/>
      </div>
    </div>

      <div v-if="loadStatus == 'loaded'">
        <div v-if="chartData && chartData.length>0" class="water-chart">
          <soilChart 
          :unit="sensor.unit"
          :data="data" />

          <soilTable
            :name="sensor.name"
            :data="data"
          />
        </div>
        <b-alert v-else show variant="info" class="pr-5">
          <div class="d-flex">
            <div class="mr-2" style="font-size:35px;">
              <font-awesome-icon icon="info-circle" />
            </div>
            <div>
              <p>
                Sem dados do sensor no período selecionado.
              </p>
              <p>
                Para visualizar as informações de umidade deste sensor,
                selecione outro intervalo de datas.
              </p>
            </div>
          </div>
        </b-alert>
      </div>
      <c-loading v-else :status="loadStatus"></c-loading>
    </b-card>
  </pageTemplate>
</template>

<script>
import { DateTime } from 'luxon';

import soilSvg from "./soilSvg";
import soilChart from "./soilChart";
import sensSoil from "./sensSoil";
import soilInfo from "./soilInfo";
import pageTemplate from "../sensorDetailsTemplate";
import soilTable from "./soilTable"

export default {
  extends: sensSoil,

  data() {
    return {
      loadStatus: "loading",
      dateRange: null,
    };
  },
  computed:{
    chartData(){
      return this.data;
    }
  },
  mounted() {
    let end = new Date(Date.now());
    end.setHours(0,0,0,0)
    let start = new Date(Date.now());
    start.setHours(start.getHours()-this.sensor.defaultPeriod)
    start.setHours(0,0,0,0)
    this.dateRange = [start, end];
  },

  methods: {
    loadData() {

      this.loadStatus = "loading";
      let start =  Math.round(DateTime.fromJSDate(this.dateRange[0]).toSeconds())
      let end   =  Math.round(DateTime.fromJSDate(this.dateRange[1]).plus({ days: 1 }).toSeconds())


      this.$http.get("sens/sensor/"
      +this.sensorId
      +"/serie?start="+start
      +"&end="+end
      ).then(
        (data) => {
          this.data = data;
          this.loadStatus = "loaded";
        },
        (error) => {
          console.error("load soil sensor error:", error);
          this.loadStatus = "error";
        }
      );
    },
  },

  watch:{
    dateRange(){
      this.loadData();
    }
  },

  components: {
    soilSvg,
    soilChart,
    soilInfo,
    pageTemplate,
    soilTable
  },
};
</script>

<style lang="scss" scoped>
.weather-chart {
  margin: 1rem 0px;
}
</style>
