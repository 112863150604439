<template>
  <div>
    <simple-map>
      <template v-slot:layer>
        <selectArea v-model="editableField.geometryArea" />
      </template>
    </simple-map>
  </div>
</template>

<script>
import selectArea from "@components/map/selectArea";
// import dripMap from "../equipment/dripMap";
import field from "./field";

export default {
  mixins: [field],

  data() {
    return {
      editableField: undefined,
    };
  },

  beforeMount() {
    this.editableField = JSON.parse(JSON.stringify(this.field));
  },

  watch: {
    editableField: {
      deep: true,
      handler(v) {
        this.$emit("update:field", v);
      },
    },
  },

  components: {
    selectArea,
    // "equipment-map-Drip": dripMap
  },
};
</script>

<style></style>
