<template>
  <div class="map-filter">
      <button class="map-button"  @click="visible = !visible">
        <font-awesome-icon icon="filter" />
      </button>
    <b-collapse v-model="visible">
      <div class="panel">
       <slot></slot>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
    };
  }
};
</script>

<style></style>
