<template>
  <b-container>
    <div class="config-page">
      <div class="header">
        <h2>Usuários</h2>
        <small>
          <font-awesome-icon icon="info-circle" />
          Lista de usuários cadastrados no grupo
        </small>
      </div>

      <!-- MENU de ferramentas  -->
      <b-row class="button-container">
        <b-button size="sm" :to="{name:'memberNew'}" variant="success">Novo Usuário</b-button>
        <b-button class="ml-2" size="sm" :to="{name:'memberInvite'}" variant="primary">Criar convite</b-button>
      </b-row>

      <!-- LISTA -->
      <b-card class="shadow" no-body v-if="loadStatus=='loaded'">
        <b-tabs card content-class="mt-3">
          <b-tab :title="`Usuário ativos (${activeUsers.length})`" active>
            <div>
              <memberCard v-for="member in activeUsers" :key="member.id" :member="member" v-on:update-member="updateList($event)"></memberCard>
            </div>
          </b-tab>
          <b-tab :title="`Usuário inativos (${disabledUsers.length})`">
            <b-alert v-if="disabledUsers.length==0" show>
              <div class="d-flex">
                <div class="mr-2" style="font-size:35px;">
                   <font-awesome-icon icon="info-circle" />
                </div>
                <div>
                  Os usuários desativados permanecem no sistema,
                  mas não podem fazer login e não estão incluídos na
                  sua contagem de usuários ativos. Usuários desabilitados
                  podem ser restaurados a qualquer momento.
                </div>
              </div>
            </b-alert>
            <div v-else>
              <memberCard v-for="member in disabledUsers" :key="member.id" 
              :member="member" 
              v-on:update-member="updateList($event)"
              v-on:remove-member="removeMember($event)"
              ></memberCard>
            </div>
          </b-tab>
        </b-tabs>
        <!-- {{memberList.map(e=>e.active)}} -->
      </b-card>
      <c-loading :status="loadStatus" v-else></c-loading>
    </div>
  </b-container>
</template>

<script>
import memberCard from "./memberCard";

export default {
  data() {
    return {
      memberList: [],
      loadStatus: String,
      perPage: 10,
      currentPage: 1,
      filter: null
    };
  },

  beforeMount() {
    this.loadStatus = "loading";
    this.$http.get("account/member").then(
      data => {
        this.memberList = data;
        this.loadStatus = "loaded";
      },
      error => {
        console.log("load member error:", error);
        this.loadStatus = "error";
      }
    );
  },
  components: {
    memberCard
  },
  computed: {
    activeUsers() {
      return this.memberList.filter(e => e.active);
    },
    disabledUsers() {
      return this.memberList.filter(e => !e.active);
    }
  },
  methods: {
    //atualiza a lista de membros
    updateList(newMember) {
      const index = this.memberList.findIndex(e => e.id === newMember.id);
      if (index === -1) {
        this.memberList.push(newMember);
      } else {
        let dataObj = this.memberList[index];
        Object.entries(newMember).forEach(([key, value]) => {
          dataObj[key] = value;
        });
      }
    },

    removeMember(id) {
      this.$bvModal
        .msgBoxConfirm(
          "Deseja realmente apagar este registro permanentemente?",
          {
            title: "Por favor confirme",
            size: "sm",
            buttonSize: "sm",
            okVariant: "danger",
            okTitle: "Sim",
            cancelTitle: "Não",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          if (value === true) {
            this.$http.delete("account/member/" + id).then(
              () => {
                let index = this.memberList.findIndex(e => e.id === id);
                this.memberList.splice(index, 1);
              },
              error => {
                 console.log("remove member error:", error);
              }
            );
          }
        })
    }
  }
};
</script>

