<template>
  <b-modal
  scrollable
    no-close-on-backdrop
    no-fade
    hide-footer
    @hidden="close"
    ref="fieldDetails-modal"
    size="max-xl"
  >
    <template v-if="field!== undefined" v-slot:modal-title>
      <b-link  :to="{name:'equipmentDetails', params:{id:field.equipmentId} }">{{equipmentName}}</b-link> /
      <span v-if="$route.name === 'fieldEdit'"><b-link :to="{name:'fieldDetails',params:{id:field.id} }">{{field.name }}</b-link> / Editar </span>
      <span v-else> {{field.name || 'Nova Parcela'}}</span>
    </template>
    <router-view v-if="field!== undefined" :field="field"></router-view>
  </b-modal>
</template>

<script>
import field from "./field";

export default {
  mixins: [field],
  // extends:field,
  mounted() {
    this.$refs["fieldDetails-modal"].show();
  },

  methods: {
    close() {
      this.$router.push({ name: "home" });
    }
  }
};
</script>
