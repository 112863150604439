<template>
  <fullscreen class="heat-map" v-model="fullscreen" @change="fit(300);">
      <vl-map
        :load-tiles-while-animating="true"
        :load-tiles-while-interacting="true"
        data-projection="EPSG:4326"
        ref="heatmap"
        @singleclick="onMapSingleClick"
      >
        <vl-view ref="view" :maxZoom="19" projection="EPSG:4326"> </vl-view>

        <!-- TILE LAYERS -->
        <vl-layer-tile
          :z-index="1"
          v-for="layer in baseLayers"
          :key="layer.source"
          :id="layer.source"
          :visible="layer.visible"
        >
          <component
            :is="'vl-source-' + layer.source"
            v-bind="layer"
          ></component>
        </vl-layer-tile>

        <vl-layer-image v-if="client && client.geometryArea" :z-index="2">
          <vl-source-idw
            :points="points"
            :area="client.geometryArea"
            :power="2"
            :pixelSize="2"
            :legends="true"
            :names="false"
            :alphaColor="0.9"
            :colorShift="3"
            :gradient="gradient"
            @startrender="rendering = true"
            @endrender="rendering = false"
          ></vl-source-idw>
        </vl-layer-image>

        <!-- camadas de vetores -->
        <vl-layer-vector :z-index="3">
          <vl-source-vector ref="objSource" @mounted="onMapMounted">
            <!-- Client -->
            <clientMap
              :key="client.id"
              :client="client"
              :showLocation="false"
            />
          </vl-source-vector>
        </vl-layer-vector>
      </vl-map>

      <div class="ol-touch ol-unselectable map-control">
        <button
          class="map-fit map-button"
          type="button"
          @click="fit(300, [10, 10, 10, 10])"
          title="ajustar mapa"
        >
          <font-awesome-icon icon="search-location" />
        </button>
        <button
          class="map-fullscreen map-button"
          type="button"
          @click="fullscreen = !fullscreen;"
          title="fullScreen"
        >
          <font-awesome-icon v-if="!fullscreen" icon="expand" />
          <font-awesome-icon v-else icon="compress" />
        </button>

        <selectLayer :baseLayers="baseLayers" />

        <!-- <div class="map-date-select"></div> -->

        <div class="map-rain-legend">
          <rainScale :gradient="gradient" :max="maxValue" :min="minValue" />
        </div>

        <slot></slot>

      </div>
  </fullscreen>
</template>

<script>
import map from "@components/map/map";
import clientMap from "@accountcomponents/client/clientMap";
import ScaleLine from "ol/control/ScaleLine";
// import FullScreen from "ol/control/FullScreen";
import selectLayer from "@components/map/selectLayer";
import rainScale from "./rainScale";
import IdwVueSource from "@components/map/heatMap";
import Vue from "vue";
// import { resolve } from "path";

// import dataTest from "./teste/dataTeste.json";

Vue.use(IdwVueSource);

export default {
  extends: map,
  data() {
    return {
      fullscreen: false,
      rendering: false,
      minValue: 0,
      maxValue: 0,
      gradient: [
        // "#FFFFFF",
        "#A4E0B7",
        "#34B8C0",
        "#6474b9",
        "#5C274C",
        // "#F3FBEE",
        // "#92D5CA",
        // "#4D9AC5",
        // "#084182",
      ],
    };
  },

  props: {
    points: Array,
    client: Object,
  },

  watch:{
    points(){
      this.updateMaxMin()
    }
  },
  mounted(){
    this.updateMaxMin()
  },


  methods: {
    updateMaxMin(){
      let maxValue = 0;
      let minValue = Infinity;
      this.points.forEach(p=>{
        if(maxValue < p.val) maxValue = p.val
        if(minValue > p.val) minValue = p.val

      })
      this.maxValue = maxValue
      this.minValue = minValue
    },

    updateSize() {
      this.$refs.heatmap.updateSize();
    },

    mapConfig() {
      // now ol.Map instance is ready and we can work with it directly
      this.$refs.heatmap.$map.getControls().extend([
        new ScaleLine({ className: "map-scale-line" }),
        // new FullScreen({ className: "map-zoom" }),
      ]);
    },

    onMapSingleClick(evt) {
      this.mousePosClick = evt.coordinate;
    },

  },


  components: {
    clientMap,
    selectLayer,
    rainScale,
  },
};
</script>

<style lang="scss" scoped>
.heat-map {
  position: relative;
  width: 100%;
  height: 100%;
}

.map-rain-legend {
  position: absolute;
  bottom: 3em;
  right: 0.5em;
}
</style>
