<template>
    <page-float-card fluid="lg" title="Resumo das Parcelas" subTitle="Relatório compilado com as métricas das parcelas">
        <div v-if="loadStatus == 'loaded'">
            <b-row>
                <b-col class="mb-2" sm="12" md="6" lg="6">
                    <b-input-group size="sm">
                        <b-input-group-prepend v-if="fieldType == 'active'" is-text>
                            Intervalo completo das parcelas ativas
                        </b-input-group-prepend>

                        <b-input-group-prepend v-if="fieldType == 'interval'">
                            <data-range v-model="dateRange" />
                        </b-input-group-prepend>

                        <b-input-group-append>
                            <b-dropdown text="" size="sm" variant="outline-secondary">
                                <b-dropdown-item @click="selectType('active')">Ativas</b-dropdown-item>
                                <b-dropdown-item @click="selectType('interval')">Intervalos</b-dropdown-item>
                            </b-dropdown>
                        </b-input-group-append>
                    </b-input-group>
                </b-col>

                <!-- <b-col class="mb-2" sm="6" md="3" lg="2">
                    <b-form-select v-model="fieldType" size="sm">
                        <b-form-select-option value="active">Parcelas Ativas</b-form-select-option>
                        <b-form-select-option value="interval">Intervalos</b-form-select-option>
                    </b-form-select>
                </b-col>
                <b-col class="mb-2" sm="6" md="3" lg="4">
                    <data-range v-model="dateRange" :disabled="fieldType == 'active'" />
                </b-col> -->






                <b-col class="mb-2" sm="6" md="4" lg="4">
                    <div class="d-flex mb-2">
                        <b-form-select size="sm" v-model="fieldsFilter" :options="statusFieldsOptions"></b-form-select>
                        <b-dropdown size="sm" variant="outline-secondary" right class="ml-1" title="colunas">
                            <b-dropdown-form class="dropdownform">
                                <b-form-checkbox v-for="field in columfields" :key="field.key" v-model="field.show">{{
                                    field.label
                                }}</b-form-checkbox>
                            </b-dropdown-form>
                            <template #button-content>
                                <font-awesome-icon icon="list" />
                            </template>
                        </b-dropdown>
                    </div>
                </b-col>

                <b-col class="mb-2 text-right" sm="6" md="2">
                    <b-button size="sm" variant="primary" @click="$printDiv('printDiv')" title="Imprimir">
                        <font-awesome-icon icon="print" />
                    </b-button>
                    <b-button class="ml-1" size="sm" variant="primary" @click="download()" title="Exportar">
                        <font-awesome-icon icon="file-excel" />
                    </b-button>
                </b-col>

            </b-row>


            <div id="printDiv">
                <report-header :dateInterval="data.interval" label="Relatório de irrigação" title="Resumo das Parcelas" />
                <!-- {{fields}} -->
                <b-table-simple responsive striped small class="report-table">
                    <b-thead class="text-center">

                        <b-tr>
                            <b-th></b-th>
                            <b-th>Equip.</b-th>
                            <b-th>Parcela</b-th>
                            <b-th colspan="2">Cultura</b-th>
                            <b-th class="line-right"> Área<br> <small>(ha)</small> </b-th>


                            <template>
                                <td v-for="f in columfieldsFilter" :key="f.key"> <b>{{ f.label }}</b><br><small
                                        v-if="f.unit">({{ f.unit }})</small></td>
                                <!-- <b-th>Dias</b-th>
                                <b-th>Irrigação<br> <small>(mm)</small></b-th>
                                <b-th>Chuva<br> <small>(mm)</small></b-th>
                                <b-th>Red. ETpc<br> <small>(%)</small></b-th> -->
                            </template>
                        </b-tr>
                    </b-thead>

                    <b-tbody>

                        <tr class="text-center" v-for="(item, index) in items" :key="index">
                            <td> <font-awesome-icon v-if="!item.active" icon="box-archive" title="No Histórico" /> </td>
                            <td> {{ item.equipmentName }}</td>
                            <td> {{ item.name }}</td>
                            <td>
                                <img v-b-tooltip.hover :title="item.culture.name" class="culture-img"
                                    :src="require('@irrigcomponents/culture/assets/48/' + item.culture.icon + '.png')" />
                            </td>
                            <td> {{ item.culture.name }}</td>
                            <td class="line-right"> {{ $filters.localeNumber((item.area / 10000), 2) }}</td>

                            <td v-for="f in columfieldsFilter" :key="f.key"> {{
                                formatNumbers(item.accumulatedMetrics[f.key], f.digits, f.mult) }}</td>
                        </tr>

                    </b-tbody>

                    <b-tfoot v-if="fieldsFilter === 'all'" class="text-center">
                        <tr>
                            <td class="text-left" colspan="5"> Total / Média <small> (considerando as áreas das parcelas
                                    )</small></td>
                            <td> {{ $filters.localeNumber((data.total.area / 10000), 2) }}</td>

                            <td v-for="f in columfieldsFilter" :key="f.key">
                                {{
                                    formatNumbers(data.total[f.key], f.digits, f.mult)
                                }}
                            </td>
                        </tr>
                    </b-tfoot>

                </b-table-simple>
            </div>
        </div>

        <c-loading :status="loadStatus" v-else>
            <b-skeleton class="mb-5" type="input"></b-skeleton>
            <b-skeleton-table :rows="8" :columns="15" :table-props="{ bordered: true, striped: false }"></b-skeleton-table>
        </c-loading>


    </page-float-card>
</template>

<script>
import { mapGetters } from "vuex";
import { DateTime } from "luxon";
import store from "@store";
import reportHeader from "../comum/reportHeader.vue";
import writeXlsxFile from "denver-write-excel-file";

export default {


    data() {
        return {
            totalAccumulated: undefined,
            dateRange: [],
            loadStatus: "",
            data: undefined,
            fieldType: "active",
            fieldsFilter: "all",
            statusFieldsOptions: [{ value: "all", text: "Todas as Parcelas" }, { value: "active", text: "Ativas" }, { value: "finished", text: "Histórico" }],
            columfields: [
                { show: true, key: "numDays", label: "Intervalo", unit:'dias', digits: 0 },
                // { show: true, key: "itn", label: "ITN", unit: 'mm', digits: 2 },
                { show: true, key: "irrigation", label: "Irrigação", unit: 'mm', digits: 2 },
                { show: true, key: "irrigationExcess", label: "Excesso Irrigação", unit: 'mm', digits: 1 },
                { show: true, key: "daysInStress", label: "Dias em estresse", digits: 0 },
                { show: true, key: "etc", label: "ETc", unit: 'mm', digits: 2 },
                { show: true, key: "etcpReduction", label: "Redução etpc", unit: '%', digits: 0, mult: 100 },
                { show: true, key: "etpc", label: "Etpc", unit: 'mm', digits: 2 },
                { show: true, key: "powerkWh", label: "Consumo", unit: 'kW', digits: 2 },
                { show: true, key: "rain", label: "Chuva", unit: 'mm', digits: 1 },
                { show: true, key: "waterExcess", label: "Excesso", unit: 'mm', digits: 1 },
                { show: true, key: "waterm3", label: "Água", unit: 'm³', digits: 1 },
            ],
        }
    },
    watch: {
        dateRange(v) {
            this.loadData(v);
        },
    },

    mounted() {
        this.loadData();
    },

    computed: {
        items() {
            var f = []

            this.data.equipments.forEach(equipment => {
                equipment.fields.forEach(field => {
                    if (this.fieldsFilter === "all" || (this.fieldsFilter === "active" && field.active == true) || (this.fieldsFilter === "finished" && field.active == false))

                        f.push({ ...{ equipmentName: equipment.name }, ...field })
                })
            });

            // this.totalAccumulated = { areaHa: areaHa, irrigation: irrigation, rain: rain/count, etc: 0, etpcReduction: 0 }

            return f
        },

        columfieldsFilter() {
            return this.columfields.filter(e => e.show == true)
        },

        ...mapGetters({
        user: "accounts/user",
        client: "accounts/client",
        }),

    },

    methods: {

        selectType(type) {
            if (type != this.fieldType && type == 'active') {
                this.loadData();
                this.dateRange = []
            }

            this.fieldType = type
        },

        exportFormatNumbers(value, mult = 1) {
            value *= mult
            return value
        },

        exportNumberFormat(digits) {
            if (digits > 0)
                return "0.00"
            return "0"
        },

        formatNumbers(value, digits = 1, mult = 1) {
            if (typeof value !== 'number') return value;
            value *= mult
            return value.toLocaleString('pt-br', { minimumFractionDigits: digits, maximumFractionDigits: digits })

        },

        loadData(interval) {
            var clientId = store.getters["accounts/clientId"];
            this.loadStatus = "loading";
            let urlParams = ""

            if (interval) {
                let startDate = DateTime.fromJSDate(interval[0]).toISODate()
                let endDate = DateTime.fromJSDate(interval[1]).toISODate()
                urlParams = "?start=" + startDate + "&end=" + endDate
            }


            this.$http.get("irrig/report/client/" + clientId + urlParams).then(
                (data) => {
                    this.data = data;
                    this.loadStatus = "loaded";

                },
                (error) => {
                    console.error(error);
                    this.loadStatus = "loaded";
                }
            );
        },


        async download() {
            let headerColor = "#dde8cb";
            this.savingFile = true;
            let rows = [];


            let columns = [{ width: 15 }, { width: 15 }, { width: 10 }, { width: 8 }];

            let headLabel = ["Equipamento", "Parcela", "Cultura", "Área\n(ha)"]
            this.columfieldsFilter.forEach((e) => {
                headLabel.push(e.label + (e.unit ? '\n(' + e.unit + ')' : ''));
                columns.push({ width: (e.label.length / 2) + 5 });
            });

            let head = []
            headLabel.forEach((e) => {
                head.push({
                    value: e,
                    height: 30,
                    alignVertical: "center",
                    align: "center",
                    borderStyle: "thin",
                    backgroundColor: headerColor,
                });
            });
            rows.push(head);




            this.items.forEach((value) => {
                let row = [
                    { value: value.equipmentName, borderStyle: "thin" },
                    { value: value.name, borderStyle: "thin" },
                    { value: value.culture.name, borderStyle: "thin" },
                    { value: (value.area / 10000), type: Number, borderStyle: "thin", format: "0.00" }
                ];

                this.columfieldsFilter.forEach((f) => {
                    row.push({
                        value: this.exportFormatNumbers(value.accumulatedMetrics[f.key], f.mult),
                        type: Number, borderStyle: "thin", format: this.exportNumberFormat(f.digits)
                    });
                });
                rows.push(row);
            });



            // let total = [
            //     {
            //     value: "Total",
            //     height: 30,
            //     alignVertical: "center",
            //     borderStyle: "thin",
            //     align: "center",
            //     backgroundColor: headerColor,
            //     },
            //     {
            //     value: tabledata.totalavg,
            //     type: Number,
            //     height: 30,
            //     alignVertical: "center",
            //     borderStyle: "thin",
            //     align: "center",
            //     backgroundColor: headerColor,
            //     }
            // ];
            // tabledata.total.forEach((e) => {
            //     total.push({
            //     value: e,
            //     type: Number,
            //     height: 30,
            //     alignVertical: "center",
            //     borderStyle: "thin",
            //     align: "center",
            //     backgroundColor: headerColor,
            //     });
            // });
            // rows.push(total);


            await writeXlsxFile(rows, {
                columns,
                fileName: "RelatorioParcelas (" + this.client.name + ").xlsx",
                stickyRowsCount: 1,
                stickyColumnsCount: 0,
            });
            this.savingFile = false;
        },



    },

    components: {
        reportHeader
    }


}
</script>
<style lang="scss" scoped>
.report-table {
    .line-right {
        border-right: 2px solid #dee2e6;
    }

    .culture-img {
        height: 25px;
    }

    tr {
        font-size: 0.85em;
    }

    thead {
        th {
            vertical-align: top;
        }

        .td-header-group {
            //   border-bottom: 2px #005f8d solid;
            padding-bottom: 2px;
        }
    }

    tfoot {
        font-weight: bold;
    }
}
</style>