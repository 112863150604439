<template>
  <page-float-card no-body fluid title="Chuvas" subTitle="Histórico de chuvas de todos os pluviômetros para o período selecionado.">

    <template v-if="rainMapValid" v-slot:head-tools>
      <data-range v-model="dateRange" />
    </template>

    <div v-if="rainMapValid">
      <multipane layout="horizontal" @pane-resize-stop="panelResize">
        <div class="map-div">
        <b-overlay :show="processingData || loadStatus == 'loading'" rounded="sm" z-index="101">
        <b-card no-body class="map-card-div shadow mb-3">

          <rainHeatMap 
            :points="points"
            :client="client"
            ref="heatmap"
          >  

          <div v-if="selectDateRange" class="interval">
            {{ $filters.localeDate(selectDateRange.start)}} ~ {{$filters.localeDate(selectDateRange.end) }}
          </div>
        
        </rainHeatMap>

        </b-card>
      </b-overlay>
    </div>

    
    <multipane-resizer class="resizer"></multipane-resizer>

        <div v-if="loadStatus == 'loaded'">

          <!-- <b-card no-body class="shadow"> -->
          <b-tabs content-class="p-2 shadow border border-top-0">

            <b-tab title="Tabela">
              <rainTable :data="data" @update:rangeselect="rangeDateSelect($event)"/>
            </b-tab>

            <b-tab title="Gráfico">
              <!-- <b-button @click="clearFilter()"> voltar </b-button> -->
              <div class="charts-container">
                <chartMaxAvg
                  :data="maxAvgRainData"
                  @date-select="dateSelect($event)"
                  @update:rangeselect="rangeDateSelect($event)"
                />
              </div>
            </b-tab>

            
          </b-tabs>
        </div>
        <c-loading @retry="loadData()" :status="loadStatus" v-else>
          <b-skeleton-table
              small
              :rows="5"
              :columns="8"
              :table-props="{ bordered: true, striped: true }"
            ></b-skeleton-table>
        </c-loading>
      </multipane>
    </div>
    <b-container v-else class="py-5">
      <div class="text-center m-5">
        <div>
          <img style="width: 140px" src="@/assets/heatmap.svg" alt="" />
        </div>
        <div class="mt-4">
          <h5>Indisponível</h5>
          <p>Esta fazenda não está configurada para gerar mapas de chuva.</p>
        </div>
      </div>
    </b-container>
  </page-float-card>
</template>

<script>

import { DateTime, Interval } from "luxon";
import { mapGetters } from "vuex";
import chartMaxAvg from "./chartMaxAvg";
import rainTable from "./rainTable";

import _ from "lodash";
import  rainHeatMap  from "./rainHeatMap";


export default {
  data() {
    return {
      loadStatus: "loading",
      processingData:true,
      data: [],
      points: [],
      maxAvgRainData: [],
      dateRange: null,
      selectDateRange: undefined,
      minValue: 0,
      maxValue: 0,

      
    };
  },
  watch: {
    dateRange() {
      this.loadData();
    },

    // o método debounce evita que esta função seja chamada repetidas vezes em
    // um intervalo de tempo muito pequeno
    selectDateRange: _.debounce(function (newVal) {
      if (newVal != undefined) {
        let interval = Interval.fromISO(newVal.start + "/" + newVal.end);
        this.processMapData(interval);
      } else {
        this.processMapData();
      }
    }, 1000),
  },
  mounted() {
    let end = DateTime.local().endOf("day").toJSDate();
    let start = DateTime.local().plus({ days: -7 }).toJSDate();
    this.dateRange = [start, end];
  },

  methods: {
    panelResize() {
      this.$refs.heatmap.updateSize();
    },

    rangeDateSelect(range) {
      if(range !== this.selectDateRange){
        this.processingData = true
      }
      this.selectDateRange = range;
    },

    loadData() {
      if (!this.dateRange[0] || !this.dateRange[1]) return;

      this.rendering = true;
      this.processingData = true;
      this.selectDateRange = undefined;

      this.loadStatus = "loading";
      let start = DateTime.fromJSDate(this.dateRange[0]).toISO({
        includeOffset: false,
      });
      let end = DateTime.fromJSDate(this.dateRange[1])
        .plus({ days: 1 })
        .toISO({ includeOffset: false });

      this.$http
        .get("/sens/pluviometer/all" + "/rain/" + start + "/" + end)
        .then(
          (data) => {
            this.data = data;
            this.loadStatus = "loaded";
            this.processData();
          },
          (error) => {
            console.error("load pluviometer rain error:", error);
            this.loadStatus = "error";
            this.processingData = false
          }
        );

      // DATA TO TESTE ///////////////////////
      // this.data = dataTest;
      // this.loadStatus = "loaded";
      // this.processData();
      ////////////////////////////////
    },

 

    // eslint-disable-next-line no-unused-vars
    async processMapData(dateInterval = undefined) {

      let coordPoints = [];
      if (this.data) {
        this.data.forEach((element) => {
          let mapTotal = 0;
          let pluvio = element.pluviometer;
          let days = element.days;
          if (days != undefined) {
            days.forEach(function (day) {
              let currentDate = DateTime.fromISO(day.date);
              if (
                dateInterval == undefined ||
                dateInterval.contains(currentDate)
              ) {
                mapTotal += day.precipitation;
              }
            });
          }
          coordPoints.push({
            name: pluvio.name,
            pos: [pluvio.location.lon, pluvio.location.lat],
            val: mapTotal,
          });
        });

        this.points = coordPoints;
      }
      this.processingData = false
    },

    processData() {
      let maxAvgRainData = new Map();
      let numPluvio = 0;
      let maxValue = 0;
      let minValue = Infinity;
      if (this.data) {
        this.data.forEach((element) => {
          let total = 0;
          let days = element.days;
          numPluvio += 1;

          if (days != undefined) {
            days.forEach(function (day) {
              total += day.precipitation;
              let value = maxAvgRainData.get(day.date);
              if (value) {
                let max = value.max;
                let min = value.min;
                if (max < day.precipitation) {
                  max = day.precipitation;
                }
                if (min > day.precipitation) {
                  min = day.precipitation;
                }

                value.max = max;
                value.min = min;
                value.count += 1;
                value.sum += day.precipitation;
              } else {
                value = {
                  date: day.date,
                  max: day.precipitation,
                  min: day.precipitation,
                  sum: day.precipitation,
                  count: 1,
                };
                maxAvgRainData.set(day.date, value);
              }
            });
            if (maxValue < total) maxValue = total;
            if (minValue > total) minValue = total;
          }
        });
      }

      let avgMaxArray = [];
      for (var value of maxAvgRainData.values()) {
        let min = value.count < numPluvio ? 0 : value.min;
        avgMaxArray.push({
          date: value.date,
          max: value.max,
          min: min,
          avg: value.sum / numPluvio,
        });
      }

      //ordena por data
      avgMaxArray.sort(function (a, b) {
        return new Date(b.date) - new Date(a.date);
      });

      this.maxAvgRainData = avgMaxArray;
      this.minValue = minValue;
      this.maxValue = maxValue;
      this.processMapData();
    },
  },
  components: {
    chartMaxAvg,
    rainTable,
    rainHeatMap
  },
  computed: {
    ...mapGetters({
      pluvioList: "sensors/pluvioList",
      client: "accounts/client",
    }),

    rainMapValid() {
      return this.client.geometryArea && this.client.geometryArea.length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.map-div {
  position: relative;
  width: 100%;
  height: 300px;

  .b-overlay-wrap{
      height: 100%;
    }

  .map-card-div{
    height: 100%;
  }
}

.interval{
  border-radius: 5px;
  background-color: $black;
  padding: 2px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0.5em;
  left: 50%;
}



// .map-date-select {
//   position: absolute;
//   top: 0.5em;
//   right: 3.5em;
//   width: 260px;
// }


.charts-container {
  // background-color: $black;
  // height: calc(25vh);
  // background-color:$gray-25;
  height: 200px;
}

.resizer {
  margin-top: 2px;
  // margin-bottom: 2px;
  top: 0px;
  // height: 2px;
  // border-bottom: 1px solid #aaa;
  &:hover {
    border-bottom: 5px solid #05f;
    transition-delay: 0.5s;
    transition-duration: 0.2s;
  }
}
</style>
