<template>
    <b-container fluid="lg">



        <div v-if="loadStatus == 'loaded'">

            <div v-if="hasSchedule" id="printDiv">

                <report-header class="print-only" label="Planejamento de irrigação"/>

                <div class="mb-2 d-flex">

                    <div>
                        <h6>
                            <font-awesome-icon icon="exclamation-triangle" />
                            ATENCÂO: Caso ocorra chuva haverá alteração no planejamento de irrigação.
                        </h6>
                    </div>

                    <div class="ml-auto non-print">
                        <b-button variant="outline-primary" @click="loadSchedules" title="recarregar">
                            <font-awesome-icon icon="retweet" />
                        </b-button>
                        <b-button variant="outline-primary" @click="$printDiv('printDiv')" title="imprimir">
                            <font-awesome-icon icon="print" />
                        </b-button>
                    </div>
                </div>

                <b-tabs content-class="mt-3" nav-class="non-print">
                    <b-tab title="Semanal">
                        <weekTable :irrigSchedule="irrigSchedule" />
                    </b-tab>
                    <b-tab title="Diário">
                        <dayTables :irrigSchedule="irrigSchedule" />
                    </b-tab>
                </b-tabs>

            </div>

            <b-container v-else class="py-2">
                <div class="text-center m-5">
                    <div>
                        <img style="width: 140px" src="@/assets/planner.png" alt="" />
                    </div>
                    <div class="mt-2">
                        <h5>Nada aqui.</h5>
                        <p>Nenhuma irrigacao agendada no período</p>
                    </div>
                </div>
            </b-container>


        </div>
        <c-loading v-else :status="loadStatus"></c-loading>

    </b-container>
</template>
<script>
// import equipment from './equipment.vue';
// import { DateTime } from 'luxon';
// import { jsPDF } from "jspdf";
// import autoTable from "jspdf-autotable";
import weekTable from "./weekTable";
import dayTables from "./dayTables";

import reportHeader from "@reportcomponents/comum/reportHeader.vue";

export default {

    data() {
        return {
            loadStatus: "",
            irrigSchedule: []
        }
    },


    mounted() {
        this.loadSchedules()
    },

    methods: {


        loadSchedules() {

            this.loadStatus = "loading";
            this.$http.get("/irrig/equipment/irrigationSchedules").then(
                (data) => {
                    this.irrigSchedule = data;
                    this.loadStatus = "loaded";
                },
                (error) => {
                    console.error("irrigSchedule error:", error);
                    this.loadStatus = "error";
                }
            );

        }

    },

    computed: {


        hasSchedule() {
            return this.irrigSchedule.length > 0
        },

        irrigList() {
            let list = []
            this.equipments.forEach(eq => {
                eq.fields.forEach(fl => {

                    if (fl.status.schedule) {
                        list.push({
                            equipment: eq,
                            field: fl,
                            days: fl.status.schedule
                        })
                    }

                })
            })

            return list;
        }

    },

    components: {
        reportHeader,
        weekTable,
        dayTables

    }

}
</script>
