<template lang="">
  <fullscreen v-model="fullscreen" @change="chageFullscreen()">
    <div class="map-div" v-if="visible === true">
      <vl-map
        :load-tiles-while-animating="true"
        :load-tiles-while-interacting="true"
        data-projection="EPSG:4326"
        ref="map"
        @singleclick="onMapSingleClick"
        @pointermove="onMapPointermove"
        @dblclick="onMapSingleClick"
        @postcompose="onMapPostCompose"
      >
        <vl-view ref="view" 
        :maxZoom="19"
        :minZoom="5"
         projection="EPSG:4326">
         </vl-view>

        <!-- TILE LAYERS -->
        <vl-layer-tile
          :z-index="1"
          v-for="layer in baseLayers"
          :key="layer.source"
          :id="layer.source"
          :visible="layer.visible"
        >
          <component
            :is="'vl-source-' + layer.source"
            v-bind="layer"
          ></component>
        </vl-layer-tile>

        <ndviLayer v-if="showNDVI" />

        <!-- Mapa de chuva -->
        <heatMapPluvio v-if="showHeatMap" />

        <!-- camadas de vetores -->
        <vl-layer-vector :z-index="3">
          <vl-source-vector ref="objSource" @mounted="onMapMounted">
            <!-- FIELDS -->
            <!-- TODO:  um erro tá acontecendo aqui quando se cria um novo equipamento -->
            <tbody v-if="showFields === true">
              <field-map
                v-for="field in fields"
                :field="field"
                :showDetail="field.id === compSelectedId"
                :datailsPos="mousePosClick"
                :showWaterColor="showFieldColor"
                :key="field.id"
              >
              </field-map>
            </tbody>

            <!-- overlay com o nome da feature -->
            <vl-overlay
              v-if="
                featurePointer != undefined && this.compSelectedId == undefined
              "
              :position="featurePointer.coordinates"
              :offset="[-48, -20]"
              positioning="bottom-left"
            >
              <div class="map-popup">
                <div
                  v-for="(value, index) in featurePointer.titles"
                  :key="value + index"
                >
                  {{ value }} <br />
                </div>
              </div>
            </vl-overlay>

            <!-- EUIPMANTOS DE IRRIGACAO -->
            <tbody v-for="equipment in equipments" :key="equipment.id">
              <component :is="'map-' + equipment.type" :equipment="equipment" />
            </tbody>

            <!-- SENSORES -->
            <tbody v-if="showSensors === true">
              <component
                :is="'sensor-' + sensor.type"
                v-for="sensor in mapSensors"
                v-bind:key="sensor.id"
                v-bind:sensor="sensor"
                :showDetail="sensor.id === compSelectedId"
              >
              </component>
            </tbody>

            <!-- Client -->
            <clientMap :key="client.id" :client="client" />
          </vl-source-vector>
        </vl-layer-vector>

        <slot></slot>
      </vl-map>

      <div class="map-logo" v-if="fullscreen">
        <img class="logo" height="40rem" src="@/assets/logobranco.png" alt="" />
      </div>

      <div class="stations-widget-box">
        <stationMiniWidget v-for="sensor in stationsSensors" :sensor="sensor" :key="sensor.id"/>
      </div>


      <div class="ol-touch ol-unselectable map-control">
        <button
          class="map-fit map-button"
          type="button"
          @click="fit(300)"
          title="ajustar mapa"
        >
          <font-awesome-icon icon="search-location" />
        </button>

        <button
          class="map-fullscreen map-button"
          type="button"
          @click="toggleFullscreen()"
          title="fullScreen"
        >
          <font-awesome-icon v-if="!fullscreen" icon="expand" />
          <font-awesome-icon v-else icon="compress" />
        </button>

        <!-- <div class="map-logo"> kalliandra </div> -->
        <selectLayer :baseLayers="baseLayers" />
        <mapFilterSelect>
          <b-form-checkbox v-model="showNDVI" name="check-button" switch
            >NDVI</b-form-checkbox
          >
          <b-form-checkbox v-model="showFields" name="check-button" switch
            >Parcelas</b-form-checkbox
          >
          <b-form-checkbox v-model="showSensors" name="check-button" switch
            >Sensores</b-form-checkbox
          >
          <b-form-checkbox v-model="showHeatMap" name="check-button" switch
            >Mapa de chuva</b-form-checkbox
          >
        </mapFilterSelect>
      </div>

      <!-- LEGENDA DE CORES -->
      <!-- <div v-if="showFieldColor" class="field-legend-panel"></div> -->

      <!-- <div style="padding: 20px">
       Zoom: {{ zoom }}<br>
       Center: {{ center }}<br>
       Rotation: {{ rotation }}<br>
       My geolocation: {{ geolocPosition }}<br>
     </div> -->

      <!-- <div>
    {{mapSensors.filter(e=>{e.type=="waterLevel"})}}
    </div> -->
    </div>
  </fullscreen>
</template>

<script>
import map from "./map";

//import * as eventCondition from 'ol/events/condition'

//import { createProj, addProj, findPointOnSurface, createStyle, createMultiPointGeom, loadingBBox } from 'vuelayers/lib/ol-ext'
import { mapState, mapGetters } from "vuex";

import ScaleLine from "ol/control/ScaleLine";
// import FullScreen from "ol/control/FullScreen";


import stationMiniWidget from "@sensorcomponents/sensStation/stationMiniWidget";
import pivotMap from "@sensorcomponents/sensPivot/pivotMap";
import stationMap from "@sensorcomponents/sensStation/stationMap";
import soilMap from "@sensorcomponents/sensSoil/soilMap";
import pluvioMap from "@sensorcomponents/sensPluvio/pluvioMap";
import waterMap from "@sensorcomponents/sensWater/waterMap";
import wellMap from "@sensorcomponents/sensWell/wellMap";
import hydrometerMap from "@sensorcomponents/sensHydro/hydrometerMap";
import fieldMap from "@irrigcomponents/field/fieldMap";
import clientMap from "@accountcomponents/client/clientMap";
import mapPivot from "@irrigcomponents/equipment/pivotMap";
import mapDrip from "@irrigcomponents/equipment/dripMap";
import heatMapPluvio from "./currentPluvioHeatMap";

import selectLayer from "./selectLayer";
import mapFilterSelect from "./mapFilterSelect";
// import {Control} from 'ol/control';
// import {RotateNorthControl} from './selectLayer.js'

import ndviLayer from "./ndviLayer.vue";

export default {
  extends: map,

  props: {
    visible: Boolean,
  },

  data() {
    return {
      compSelectedId: undefined,
      showFieldColor: true,
      showNDVI: false,
      showFields: true,
      showSensors: true,
      showHeatMap: true,
      mousePosClick: undefined,
      fullscreen: false,
    };
  },

  methods: {
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
    },

    chageFullscreen() {
      this.fit(300);
    },

    updateSize() {
      this.$refs.map.updateSize();
    },
    mapConfig() {
      // now ol.Map instance is ready and we can work with it directly

      // var fullscreen = new FullScreen({
      //   className: "map-zoom",
      //   source: "map-fullscreen",
      // });

      this.$refs.map.$map.getControls().extend([
        new ScaleLine({ className: "map-scale-line" }),
        // fullscreen,

        //   new OverviewMap({
        //     collapsed: false,
        //     collapsible: true,
        //   }),
        //   new ZoomSlider(),
      ]);
    },
    onMapSingleClick(evt) {
      this.mousePosClick = evt.coordinate;
      let features = this.$refs.map.$map.getFeaturesAtPixel(evt.pixel);
      if (!features) {
        if (this.compSelectedId) this.compSelectedId = undefined;
        return;
      }

      for (var i = 0; i < features.length; i++) {
        if (features[i].values_.sensor != undefined) {
          this.$store.dispatch("select/setSensor", features[i].values_.sensor);
          break;
        }

        this.compSelectedId = features[i].values_.compId;
        if (this.compSelectedId !== undefined) break;
      }
    },
  },

  computed: {
    // singleClick:function(){return eventCondition.singleClick},
    // pointerMove:function(){return eventCondition.pointerMove},

    ...mapState({
      sensors: (state) => state.sensors.sensors,
      equipments: (state) => state.equipments.equipments,
      stationsSensors: (state) => state.sensors.sensors.filter(s => s.type == 'station')
    }),

    ...mapGetters({
      fields: "equipments/fields",
      client: "accounts/client",
    }),
  },

  components: {
    "sensor-pivot": pivotMap,
    "sensor-station": stationMap,
    "sensor-soil": soilMap,
    "sensor-pluviometer": pluvioMap,
    "sensor-waterLevel": waterMap,
    "sensor-well": wellMap,
    "sensor-hydrometer": hydrometerMap,
    "field-map": fieldMap,
    "map-Pivot": mapPivot,
    "map-Drip": mapDrip,
    clientMap,
    heatMapPluvio,
    selectLayer,
    mapFilterSelect,
    ndviLayer,
    stationMiniWidget
  },

  watch: {
    showNDVI(v) {
      if (v) {
        this.showFields = false;
        // showSensors: true,
        this.showHeatMap = false;
      } else {
        this.showFields = true;
        this.showHeatMap = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.map-select {
  top: 0.5em;
  left: 0.15em;
}

.stations-widget-box {
  position: absolute;
  right: .5em;
  bottom: 2.5em;

  // @media (max-width: 450px) {
  //     display: none;
  // }
}


.map-div {
  position: relative;
  width: 100%;
  height: 100%;

  .map {
    height: 100%;
    width: 100%;
  }
}

.overlay-content {
  background: #efefef;
  box-shadow: 0 5px 10px rgba(2, 2, 2, 0.2);
  padding: 10px 20px;
  font-size: 16px;
}
</style>
