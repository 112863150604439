
// import xl from "excel4node";
// import { saveAs } from "file-saver";

import writeXlsxFile from 'denver-write-excel-file'

export const tableExport ={

    data() {
        return {
          savingFile:false,
        };
      },
      props: {
        fileName:{type:String, default:"filename"}
      },
      computed: {
        numRows() {
          if (this.items !== undefined) return this.items.length;
          else return 0;
        },
      },
    
      methods: {

        // download2() {
        //   console.log("salvando")
        //   this.savingFile = true;
  

        //   let wb = new xl.Workbook();
        //   let ws = wb.addWorksheet('Tabela 1');
        //   let style = wb.createStyle({
        //     font: {
        //       color: '#000000',
        //       size: 12,
        //     },
        //     // fill: {patternType: '#ABABAB'},
        //     numberFormat: '$#,##0.00; ($#,##0.00); -',
        //   });
        
        //   // let headers = [];
        //   let col = 1;
        //   this.fields.forEach(e=>{
        //     ws.cell(1,col).string(e.label).style(style)
        //     .style({
        //       font:{bold: true},
        //       alignment: { wrapText: true}
        //     });
        //     col += 1;
        //   })
         
          

        //   let row = 2;
        //   this.items.forEach((element) => {
        //     let col = 1;
        //      this.fields.forEach(e=>{
        //         let v = element[e.key];

        //         if(e.exlformatter){
        //           v = e.exlformatter(v)
        //         }else if (e.formatter) v = e.formatter(v)

        //         let t = typeof(v)

        //         if(t == 'object')
        //           ws.cell(row,col++).date(v).style({numberFormat: 'dd/mm/yy HH:MM'});
        //         else  if(t == 'number')
        //           ws.cell(row,col++).number(v);
        //         else
        //           ws.cell(row,col++).string(v);
        //     })
        //     row ++;
        //   });
    
        //   ws.column(1).setWidth(20);
        //   ws.column(2).setWidth(20);
        //   wb.write(this.fileName+".xlsx");

        //   // wb.write(this.fileName+".xlsx", function(err, stats) {
        //   //   if (err) {
        //   //     console.error(err);
        //   //   } else {
        //   //     console.log(stats); // Prints out an instance of a node.js fs.Stats object
        //   //   }
        //   // });

        //   // wb.writeToBuffer().then(buffer => {
        //   //   // Do something with buffer
        //   //   console.log(buffer)
        //   // });

        //   // wb.writeToBuffer().then(buffer => {
        //   //   saveAs(
        //   //     new Blob([buffer], { 
        //   //       type:
        //   //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
        //   //     }),
        //   //     this.fileName+".xlsx"
        //   //   );
        //   //   this.savingFile = false;
        //   // })

        // },

        async download() {
          this.savingFile = true;
          let rows = []
          let headers = []
          let columns =[]
          this.fields.forEach(e=>{
            headers.push({
              value:e.label,
              align:'center',
              color:e._color??"#ffffff",
              backgroundColor:e._backgroundColor??"#666666",
              wrap:true,
              height:e._height??30,
             
            })

            let width = e._width
            if (!width){
              width = e.label.length
            }

            columns.push({
              width:width+2,
            })
          })

          rows.push(headers);

          this.items.forEach((element) => {
            let data = []
             this.fields.forEach(e=>{
                let v = element[e.key];

              if(e.exlformatter){
                v = e.exlformatter(v)
              } else 
              if(e.xlsType != undefined && e.xlsType.formatter != undefined){
                v = e.xlsType.formatter(v)
              }else if (e.formatter) v = e.formatter(v)


              if(e.xlsType != undefined ){
                  data.push({type:e.xlsType.type, value:v,  align:e.xlsType.align, format:e.xlsType.format})

              }else{

                  
    
                  if (v != undefined){
                    let t = typeof(v)
                    if(t == 'object')
                      data.push({type:Date, value:v, format:'dd/mm/yy HH:MM'})
                      // ws.cell(row,col++).date(v).style({numberFormat: 'dd/mm/yy HH:MM'});
                    else  if(t == 'number')
                      data.push({type:Number, value:v,   align:'center'})
                      // ws.cell(row,col++).number(v);
                    else
                      data.push({type:String, value:v,  align:'center'})
                      // ws.cell(row,col++).string(v);  
                  }else{
                    data.push({type:String, value:"",  align:'center'})
                  }
                }
            })
            rows.push(data)
          });

          
          

          await writeXlsxFile(rows,{
            columns,
            fileName: this.fileName+".xlsx"
          })

          this.savingFile = false;

        }
      },


}