
import Vue from 'vue'


Vue.directive('fragments', {
  inserted: function (el) {
    const children = Array.from(el.children)
    const parent = el.parentElement
    children.forEach((item) => { parent.appendChild(item) })
    parent.removeChild(el)
  }
});













import './components/multipane';


/***********************************************************
*  custom ICONS
*/
import '@components/klicons'




/** componente de mapa simples */

import simpleMap from "@components/map/simpleMap"
Vue.component('simple-map', simpleMap)




/***********************************************************
*  bootstrap
*/
import { BootstrapVue } from 'bootstrap-vue'
Vue.use(BootstrapVue)
//Vue.use(BootstrapVueIcons)
// import BModal from 'bootstrap-vue/es/components/modal/modal'
// import BModalDirective from 'bootstrap-vue/es/directives/modal/modal'
// Vue.component('b-modal', BModal)
// Vue.directive('b-modal', BModalDirective)
//
// import BButton from 'bootstrap-vue/es/components/button/button'
// Vue.component('b-button', BButton)
//
// import BCard from 'bootstrap-vue/es/components/card/card'
// Vue.component('b-card', BCard)

// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'

import './css/bootstrap.scss'






/***********************************************************
*  font awesome
*/
import { library } from '@fortawesome/fontawesome-svg-core'


import {
  faArrowsAltV, faCheck, faArrowRight,
  faClock, faArrowCircleUp, faEdit,faClockRotateLeft,
  faTrash, faHistory, faLeaf,faPlus,faBoxArchive,
  faInfoCircle, faChevronDown, faChevronUp,faChevronRight,
  faCalendar, faExclamationTriangle, faCopy,faPaste,
  faSearchLocation, faFillDrip, faBan, faRecycle,
  faSpinner, faLock, faUser,faUserShield, faCogs, faHome,
  faLayerGroup, faClipboard, faSeedling, faTable, faList,
  faBuilding, faCheckCircle, faCalendarTimes,
  faMap, faSatellite, faChartPie, faWifi,
  faChartLine, faRssSquare, faSyncAlt, faBars,faFileArrowDown,
  faFileExport, faFileExcel, faFilePdf, faBraille, faFilter,
   faPlug, faPrint, faPencilAlt, faPlayCircle,
  faStopCircle, faForward, faAngleDoubleUp, faChevronCircleUp,
  faExpand, faCompress, faStopwatch, faRetweet, faCloud, faImage,faGear,
  faPlugCircleXmark,faRightToBracket,faRightFromBracket, faFlag,
  faWind,faCloudRain,faSun,faTemperatureHalf,faDroplet,faRobot,
  faXmark

} from '@fortawesome/free-solid-svg-icons'


library.add(faArrowsAltV)
library.add(faCheck)
library.add(faArrowRight)
library.add(faClock)
library.add(faClockRotateLeft)

library.add(faArrowCircleUp)
library.add(faEdit)
library.add(faTrash)
library.add(faHistory)
library.add(faLeaf)
library.add(faPlus)
library.add(faBoxArchive)
library.add(faInfoCircle)
library.add(faChevronRight)
library.add(faChevronDown)
library.add(faChevronUp)
library.add(faCalendar)
library.add(faExclamationTriangle)
library.add(faCopy)
library.add(faPaste)
library.add(faSearchLocation)
library.add(faFillDrip)
library.add(faBan)
library.add(faRecycle)
library.add(faSpinner)
library.add(faSyncAlt)
library.add(faLock)
library.add(faUser)
library.add(faUserShield)
library.add(faCogs)
library.add(faHome)
library.add(faLayerGroup)
library.add(faClipboard)
library.add(faSeedling)
library.add(faTable)
library.add(faList)
library.add(faBuilding)
library.add(faCheckCircle)
library.add(faCalendarTimes)
library.add(faSatellite)
library.add(faMap)
library.add(faChartPie)
library.add(faWifi)
library.add(faChartLine)
library.add(faRssSquare)
library.add(faFileArrowDown)
library.add(faFileExport)
library.add(faFileExcel)
library.add(faFilePdf)
library.add(faBraille)
library.add(faFilter)
library.add(faBars)
library.add(faPlug)
library.add(faPrint)
library.add(faPencilAlt)
library.add(faPlayCircle)
library.add(faStopCircle)
library.add(faForward)
library.add(faAngleDoubleUp)
library.add(faChevronCircleUp)
library.add(faExpand)
library.add(faCompress)
library.add(faStopwatch)
library.add(faRetweet)
library.add(faCloud)
library.add(faImage)
library.add(faGear)
library.add(faPlugCircleXmark)
library.add(faRightToBracket)
library.add(faRightFromBracket)


library.add(faFlag)


library.add(faWind)
library.add(faCloudRain)
library.add(faSun)
library.add(faTemperatureHalf)
library.add(faDroplet)

library.add(faRobot)

library.add(faXmark)


import {falHouse} from "@components/klicons/icons/house"
import {falLayerGroup} from "@components/klicons/icons/layerGroup"
import {falSeedling} from "@components/klicons/icons/seedling"
import {falClipboard} from "@components/klicons/icons/clipboard"
import {falGripDots} from "@components/klicons/icons/gripDots"
import {falFileChartPie} from "@components/klicons/icons/fileChartPie"
import {falCloudRain} from "@components/klicons/icons/cloudRain"
import {falSatellite} from "@components/klicons/icons/satellite"
import {falRouter} from "@components/klicons/icons/router"
import {falStopwatch} from "@components/klicons/icons/stopwatch"
import {falCircleSmall} from "@components/klicons/icons/circleSmall"
import {falGear} from "@components/klicons/icons/gear"
import {falTransformerBolt} from "@components/klicons/icons/transformerBolt"



library.add(falHouse)
library.add(falLayerGroup)
library.add(falSeedling)
library.add(falClipboard)
library.add(falGripDots)
library.add(falFileChartPie)
library.add(falCloudRain)
library.add(falSatellite)
library.add(falRouter)
library.add(falStopwatch)
library.add(falCircleSmall)
library.add(falGear)
library.add(falTransformerBolt)



import {falWeatherStation} from "@components/klicons/icons/weatherStation"
import {falPluviometer} from "@components/klicons/icons/pluviometer"
library.add(falWeatherStation)
library.add(falPluviometer)







import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
Vue.component('font-awesome-icon', FontAwesomeIcon)





/***********************************************************
*  VueJsonPretty
*/
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css';
Vue.component('vue-json-pretty',VueJsonPretty)



/***********************************************************
*  custom CSS
*/
// import './css/variables.less'
// import './css/utils.less'
// import './css/customise.less'
// import './css/components.less'
// import './css/print.less'
// import './css/styles.scss'




/***********************************************************
*  Filtros
*/
import {
  round, secondsToStr, ptbrdate,
  decimalhourToTime, ptbrdatehour, localeDate, localeDateTime,
  currency, localeNumber, longMonth, localeTime, weekFormat,
  dayFormat, longDateFormat,dateNow
} from './filters.js'
// Vue.filter('round', round);
// Vue.filter('secondsToStr', secondsToStr);
// Vue.filter('ptbrdate', ptbrdate);
// Vue.filter('decimalhourToTime',decimalhourToTime)
// Vue.filter('ptbrdatehour',ptbrdatehour)
// Vue.filter('localeDate',localeDate)
// Vue.filter('localeDateTime',localeDateTime)
// Vue.filter('currency',currency)
// Vue.filter('localeNumber',localeNumber)
// Vue.filter('longMonth',longMonth)
// Vue.filter('localeTime',localeTime)

Vue.prototype.$filters = {
  round,
  secondsToStr,
  ptbrdate,
  decimalhourToTime,
  ptbrdatehour,
  localeDate,
  localeDateTime,
  currency,
  localeNumber,
  longMonth,
  localeTime,
  weekFormat,
  dayFormat,
  longDateFormat,
  dateNow
}







/***********************************************************
*  Custom Global componentes
*/
import loading from './components/loading'
Vue.component('c-loading', loading)


import confirmDeleteModal from './components/confirmDeleteModal';
Vue.prototype.$deleteConfirm = new Vue(confirmDeleteModal).$mount();  
// Vue.component('confirmDeleteModal',confirmDeleteModal)


import jsonEditor from "./components/jsonEditor"
Vue.component('json-editor', jsonEditor)

import inputNumber from './components/inputs/inputNumber'
Vue.component('input-number', inputNumber)

import inputTime from './components/inputs/inputTime'
Vue.component('input-time', inputTime)

import inputDate from './components/inputs/inputDate'
Vue.component('input-date', inputDate)

import inputText from './components/inputs/inputText'
Vue.component('input-text', inputText)

import inputSelect from './components/inputs/inputSelect'
Vue.component('input-select', inputSelect)

import inputArea from './components/inputs/inputArea'
Vue.component('input-area', inputArea)

import dataRange from './components/dataRange'
Vue.component('data-range', dataRange)

import monthSelect from './components/monthSelect'
Vue.component('monht-select', monthSelect)

import valueBar from "./components/valueBar"
Vue.component('value-bar', valueBar)

import editableText from "./components/editableText";
Vue.component('editable-text', editableText)

import markdown from "./components/markdown";
Vue.component('markdown', markdown)

import listPage from "./components/listPage";
Vue.component('list-page', listPage)

import pageFloatCard from "./components/PageFloatingCard";
Vue.component('page-float-card', pageFloatCard)

import selectLocation from "./components/selectLocation"
Vue.component('select-location', selectLocation)


import popoverSelect from "./components/popoverSelect";
Vue.component('popover-select', popoverSelect)


import timeline from "./components/timeline"
Vue.component('timeline', timeline)


import VueFullscreen from 'vue-fullscreen'
Vue.use(VueFullscreen)

import tableExport from "./components/tableExport"
Vue.component('table-export', tableExport)

import battery from "./components/battery"
Vue.component('battery', battery)


import printDiv from './printDiv.js'
Vue.prototype.$printDiv = printDiv



