<template>
  <div class="login-page">
    <div class="form">
      <b-form @submit.stop.prevent class="login-form">
        <b-img class="mb-5" fluid :src="require('@/assets/kalliandra.png')" alt="logo-kalliandra"/>
        <!-- <p class="h4 text-center mb-4">Login</p> -->

        <!-- <b-form-group id="fieldset-1" label="username">
          <b-input name="username" type="text" v-model="user.username"></b-input>
        </b-form-group>

        <b-form-group id="fieldset-1" label="password">
          <b-input name="password" type="password" v-model="user.password"></b-input>
        </b-form-group>-->

        <b-input name="username" type="text" placeholder="username" v-model="user.username" trim></b-input>
        <b-input name="current-password" type="password" placeholder="password" v-model="user.password" trim></b-input>

        <b-alert
          :show="dismissCountDown"
          dismissible
          variant="danger"
          fade
          @dismissed="dismissCountDown=0"
          @dismiss-count-down="countDownChanged"
        >Seu nome de usuário ou senha estão incorretos. Tente Novamente.</b-alert>

        <div class="text-center mt-4">
          <b-button v-if="status=='ready'" variant="success" @click="login"  type="submit">Entrar</b-button>
          <b-button v-else>
            Entrando
            <font-awesome-icon icon="spinner" spin/>
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>


<script>
import apiHttp from "@api/apiHttp";

export default {
  data() {
    return {
      user: { username: "", password: "" },
      dismissCountDown: 0,
      status:"ready"
    };
  },

  props:{
    nextpath:{type:String, required:false, default:"/"}
  },
  mounted(){

  },
  methods: {
    login() {
      this.$http.clearToken();
      this.status = "loggingin"
      apiHttp.post("account/login/", this.user).then(
        token => {
          this.status = "logged"
          this.$http.setToken(token, true);
          this.$router.push(this.nextpath);
        },
        error => {
          this.status = "ready"
          console.error("login error",error)
          this.dismissCountDown = 5;
        }
      );
    },
    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    }
  }
};
</script>

<style lang="scss" scoped>
.login-page {
  width: 100%;
  padding: 8% 0 0;
  margin: auto;

  .form {
    position: relative;
    z-index: 1;
    // background: #ffffff;
    max-width: 400px;
    margin: 0 auto 100px;
    padding: 45px 30px;
    text-align: center;
    // box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
    //  border: 1px solid rgba(0, 0, 0, 0.125);
      // border-radius: 0.5rem;
    input {
      width: 100%;
      border: 0;
      margin: 0 0 15px;
      padding: 15px;
    }
    button {
      text-transform: uppercase;
      width: 100%;
    }
  }

}
</style>