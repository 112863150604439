<template>
    <div>

        <b-modal ref="edit-rain-modal" header-bg-variant="warning" header-text-variant="danger" size="lg" @hidden="close()">

            <template #modal-title>
                <font-awesome-icon icon="exclamation-triangle" />
                Editar Chuva
            </template>


           
            <b-form-group class="mb-2" label-cols="4" label-cols-lg="2" label-size="sm" label="Data" label-for="input-sm">
                <b-form-datepicker size="sm"
                    :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' }"
                    v-model="editDate" :disabled="false" label-no-date-selected="select">
                </b-form-datepicker>
            </b-form-group>
            


            <div v-if="loadStatus == 'ready'" class="mt-5 mb-5 text-center">
                <h6>Selecione um dia para editar.</h6>
            </div>
         

            <div v-else-if="loadStatus == 'loaded'">
                <div style="height:70px" class="mb-2">
                    <pluvioChart :data="editData.values" />
                </div>

                <b-row>
                    <b-col v-for="col in [0, 12]" :key="col">
                        <div v-for="n, index in 12" :key="index" class="d-flex mb-1">
                            <b-input-group size="sm" :prepend="(index + col) + 'h'" append="mm">
                                <b-form-input type="number" min=0 v-model="editData.values[index + col]">
                                </b-form-input>
                            </b-input-group>
                            <div class="ml-2">
                                <b-button size="sm" variant="danger" tabindex="-1" @click="clear(index + col)"> <font-awesome-icon icon="xmark" /></b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <hr>
                <div class="text-center">
                    <b-button size="sm" variant="danger" @click="clearAll()"> 
                        <font-awesome-icon icon="trash" />
                        Limpar tudo
                    </b-button>
                </div>
            </div>
            <c-loading v-else :status="loadStatus"></c-loading>

            <template #modal-footer="{ cancel }">
                <div class="w-100">
                    <b-button variant="primary" @click="editRain()">
                        Salvar
                    </b-button>
                    <b-button class="float-right" @click="cancel()">
                        cancelar
                    </b-button>
                </div>
            </template>

        </b-modal>

        <b-modal ref="saving-rain-modal" hide-footer>
            <template #modal-header>
                <h5>Salvando...</h5>
            </template>
            <b-progress :value="100" :max="100" height="2rem" animated></b-progress>
        </b-modal>

    </div>
</template>
<script>
import { DateTime } from "luxon";
import pluvioChart from "./pluvioMiniChart";
export default {

    data() {
        return {
            loadStatus: "ready",
            editData: undefined,
            editDate: undefined,
        }
    },
    props: {
        pluvioId: String
    },

    watch: {
        editDate(v) {
            this.loadEdit(v)
        }

    },

    components: {
        pluvioChart,
    },

    methods: {

        close(){
            this.editDate = undefined
            this.loadStatus= "ready"
        },

        show(date) {
            this.loadStatus= "ready"
            this.editDate = date
            this.$refs["edit-rain-modal"].show();
        },

        loadEdit(date) {

            if (date == undefined)
                return

            this.loadStatus = "loading";
            let start = DateTime.fromISO(date).toISO({
                includeOffset: false,
            });
            let end = DateTime.fromISO(date)
                .plus({ days: 1 })
                .toISO({ includeOffset: false });

            this.$http
                .get(
                    "/sens/pluviometer/" +
                    this.pluvioId +
                    "/rain?start=" +
                    start +
                    "&end=" +
                    end
                )
                .then(
                    (data) => {
                        //this.data = data;
                        this.loadStatus = "loaded";

                        if (data.length > 0) {
                            this.editData = {
                                date: start,
                                values: JSON.parse(JSON.stringify(data[0].hourlyData)),
                            }
                        } else {
                            this.editData = {
                                date: start,
                                values: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            }
                        }
                    },
                    (error) => {
                        console.error("load pluviometer rain error:", error);
                        // this.loadStatus = "error";
                    }
                );


        },
        editRain() {
            this.$refs["edit-rain-modal"].hide();
            this.$refs["saving-rain-modal"].show();

            this.$http.put("/sens/pluviometer/" + this.pluvioId + "/rain", this.editData)
                .then(
                    () => {
                        this.$emit('saved')
                        this.$refs["saving-rain-modal"].hide();
                        this.editDate = undefined
                        // this.$bvToast.toast("dados salvos", {
                        //   title: "msg",
                        //   variant: "success",
                        //   solid: true,
                        // });
                    },
                    (error) => {
                        console.error("load pluviometer rain error:", error);
                        this.$refs["saving-rain-modal"].hide();
                        this.$bvToast.toast("Algum erro aconteceu e não foi possível salvar os dados", {
                            title: "msg",
                            variant: "danger",
                            solid: true,
                        });
                    }
                );
        },

        clearAll() {
            this.editData.values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        },

        clear(index) {
            this.$set(this.editData.values, index, 0);
        },
    }

}
</script>
