<template lang="html">
  <pageTemplate :sensor="sensor">
    <template v-slot:sensor>
      <div class="svg-image water-sensor-Color">
        <waterSvg :width="'100%'" :height="180" :sensor="sensor" />
      </div>
    </template>

    <template v-slot:info>
      <ul class="sensor-info-group">
        <li>
          <b>Status</b>
          <span>{{ sensor.status }}</span>
        </li>
        <li>
          <b>Nível Máximo</b>
          <span>{{ sensor.fullLevel }} {{sensor.unit}} </span>
        </li>
        <li>
          <b>Nível Mínimo</b>
          <span>{{ sensor.emptyLevel }} {{sensor.unit}}</span>
        </li>
        <li>
          <b>Alerta Nível Máximo</b>
          <span>{{ sensor.warningFull }} {{sensor.unit}}</span>
        </li>
        <li>
          <b>Alerta Nível Mínimo</b>
          <span>{{ sensor.warningEmpty }} {{sensor.unit}}</span>
        </li>
      </ul>
    </template>

    <b-card>

    <div class="d-flex flex-wrap mb-2">






      <div>
        <h5>Histórico do nível da água</h5>
      </div>
      <div class="ml-auto">
        <data-range v-model="dateRange" />
      </div>
 
    </div>

    <div v-if="loadStatus == 'loaded'">
      <div class="water-chart">
        <waterChart
          :emptyLevel="emptyLevel"
          :fullLevel="fullLevel"
          :warningFull="warningFull"
          :warningEmpty="warningEmpty"
          :unit="unit"
          :data="chartData"
        />

        <waterTable :data="data.levelSerie" :name="sensor.name" :unit="unit"/>

         <!-- <genericChart
            class="generic-chart"
            title="Comparação"
            color="#f40"
            :serie="chartData.levelSerie"
            unit="cm"
            name="nível"
            height="300px"
          /> -->

      </div>
    </div>

    <c-loading v-else :status="loadStatus"></c-loading>
  </b-card>
  </pageTemplate>
</template>

<script>
import { DateTime } from "luxon";

import waterSvg from "./waterSvg";
import waterChart from "./waterChart";
import sensWater from "./sensWater";
import pageTemplate from "../sensorDetailsTemplate";
import waterTable from "./waterTable"

// import genericChart from "@kl-framework/charts/genericChart";


export default {
  extends: sensWater,

  data() {
    return {
      loadStatus: "loading",
      data: [],
      dateRange: null,
    };
  },
  computed: {
    chartData() {
      return this.data;
    },
  },
  mounted() {
    let end = new Date(Date.now());
    end.setHours(0, 0, 0, 0);
    let start = new Date(Date.now());
    start.setHours(start.getHours() - this.sensor.defaultPeriod);
    start.setHours(0, 0, 0, 0);
    this.dateRange = [start, end];
  },

  methods: {
    loadData() {
      this.loadStatus = "loading";
      let start = Math.round(
        DateTime.fromJSDate(this.dateRange[0]).toSeconds()
      );
      let end = Math.round(
        DateTime.fromJSDate(this.dateRange[1])
          .plus({ days: 1 })
          .toSeconds()
      );
      this.$http
        .get(
          "sens/sensor/" +
            this.sensorId +
            "/serie?start=" +
            start +
            "&end=" +
            end
        )
        .then(
          (data) => {
            this.data = data;
            this.loadStatus = "loaded";
          },
          (error) => {
            console.error("load water sensor error:", error);
            this.loadStatus = "error";
          }
        );
    },
  },

  watch: {
    dateRange() {
      this.loadData();
    },
  },

  components: {
    waterSvg,
    waterChart,
    pageTemplate,
    waterTable
    // genericChart
  },
};
</script>
