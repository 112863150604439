import sensorApiPage from "./sensorApiPage";
// import sensorApiPageDetails from "./sensorApiPageDetails";
import { Role } from "@/helpers/role";

export default {
  path: "/external-api",
  meta: { authorize: [Role.Admin] },
  component: sensorApiPage,
  name: "externalApi",
  // children: [
  //   {
  //     path: ":id",
  //     props: (route) => ({ id: route.params.id }),
  //     component: sensorApiPageDetails,
  //     name: "externalApiEdit",
  //   }
  // ]
};
