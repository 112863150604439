/* eslint-disable no-unused-vars */
import ImageCanvas from "ol/source/ImageCanvas";
import { assign } from "ol/obj.js";
import Color from 'color'
import {generateMatrix,generateImage,getExtentArea,posTransform} from "./IDWMatrix"

var Property = {
  PIXEL_SIZE: 4,
  maxNeighbours: 12,
  POWER: 2,
  GRADIENT: "gradient",
  RADIUS: "radius",
  LEGENDS:false,
  NAMES:false,
  COLORSHIFT : 0
};

var DEFAULT_GRADIENT = [
    "#afe0",
    "#0ff6",
    "#0bf8",
    "#00fa",
    "#008c",
    "#103f",
];



function getMaxMinValue(points_,minMax=[Infinity,-Infinity]){
  if (points_) {
    points_.forEach((source) => {
      if (source.val < minMax[0]) minMax[0] = source.val;
      if (source.val > minMax[1]) minMax[1] = source.val;
    });
  }
  return minMax
}

var IdwSource = /** @class */ (function (ImageCanvas) {
  function IdwSource(opt_options) {

    var options = opt_options ? opt_options : /** @type {Options} */ ({});
    var baseOptions = assign({}, options);
    // delete baseOptions.style;
    // delete baseOptions.renderBuffer;
    // delete baseOptions.updateWhileAnimating;
    // delete baseOptions.updateWhileInteracting;
    ImageCanvas.call(this, baseOptions) || this;

    this.canvas = document.createElement("canvas");
    this.imageCanvas_ = document.createElement("canvas");
    this.matrix = undefined;


    this.minMaxValueConf = [Infinity,-Infinity]
    
    this.gradient    = baseOptions.gradient?  baseOptions.gradient  : DEFAULT_GRADIENT;
    this.power       = baseOptions.power?     baseOptions.power     : Property.POWER;
    this.pixelSize   = baseOptions.pixelSize? baseOptions.pixelSize : Property.PIXEL_SIZE;
    this.showLegends = baseOptions.legends?   baseOptions.legends   : Property.LEGENDS;
    this.showNames   = baseOptions.names?     baseOptions.names     : Property.NAMES;
    this.colorShift  = baseOptions.colorShift?baseOptions.colorShift: Property.COLORSHIFT;
    this.alphaColor  = baseOptions.alphaColor?baseOptions.alphaColor: undefined;
    this.onStartRender = baseOptions.onStartRender? baseOptions.onStartRender   : function(){};
    this.onEndRender   = baseOptions.onEndRender? baseOptions.onEndRender   : function(){};
    
    this.recalcule = true

    if(baseOptions.minValue!== undefined){ this.minMaxValueConf[0]=baseOptions.minValue  }
    if(baseOptions.maxValue!== undefined){ this.minMaxValueConf[1]=baseOptions.maxValue  }
     
  
    if (baseOptions.area) {
      this.geometricArea_ = baseOptions.area;
      this.extent = getExtentArea(this.geometricArea_);
    }


    if(baseOptions.points){
      this.points = baseOptions.points
      this.minMaxValue = getMaxMinValue(this.points,this.minMaxValueConf)
    }


    // cria array de cores RGBA
    this.cols = [];
    for (let i = 0; i < this.gradient.length; i++) {
      this.cols[i] = Color(this.gradient[i]).array()
      if(this.cols[i][3]===undefined){
        this.cols[i][3] = 1
      }
      this.cols[i][3] = (this.cols[i][3]*255)>>0
    }


    /**
     *
     * @param {*} extent
     * @param {*} resolution
     * @param {*} pixelRatio
     * @param {*} size
     * @param {*} projection
     */
    this.canvasFunction_ = function (
      extent,
      resolution,
      pixelRatio,
      size,
      projection
    ) {
      

      this.onStartRender();
      this.canvas.width = size[0];
      this.canvas.height = size[1];
      let pixelSize = (this.pixelSize*pixelRatio)>>0;
      

      let width = size[0] >> 0;
      let height = size[1] >> 0;

      // console.log("width",width)

      let maxX = -Infinity;
      let maxY = -Infinity;
      let minX = Infinity;
      let minY = Infinity;


      // cria o contorno da área e define os pontos máximos e mínimos  --
      let geometricArea = [];
      if (this.geometricArea_) {
        this.geometricArea_.forEach((e) => {
          let v = [];
          e.forEach((e2) => {
            let pos = posTransform(extent, size, e2.slice(0, 2));
            if (maxX < pos[0]) maxX = pos[0];
            if (maxY < pos[1]) maxY = pos[1];
            if (minX > pos[0]) minX = pos[0];
            if (minY > pos[1]) minY = pos[1];
            v.push(pos);
          });
          geometricArea.push(v);
        });
      }

      // let extentArea =  getExtentArea(geometricArea)

      //---------------------------------------------------------------
      

      // if (maxX > width) maxX = width;
      // if (maxY > height) maxY = height;
      // if (minX < 0) minX = 0;
      // if (minY < 0) minY = 0;

      let xoffset = minX;
      let yoffset = minY;
      let img_sizex = (maxX - minX) >> 0;
      let img_sizey = (maxY - minY) >> 0;

      pixelSize = Math.round(pixelSize * img_sizex/500.0)
      if(pixelSize < this.pixelSize) pixelSize = this.pixelSize;


      // this.imageCanvas_.width = width;
      // this.imageCanvas_.height = height;

      this.canvas.width = width;
      this.canvas.height = height;
      let ctx = this.canvas.getContext("2d");
      ctx.save();
      ctx.fillStyle = "transparent";
      ctx.fillRect(0, 0, width, height);


      if (img_sizex > pixelSize && img_sizey > pixelSize && this.points.length>1) {

        let points = [];
        this.points.forEach((e) => {
          points.push({
            destpos: posTransform(extent, size, e.pos.slice(0, 2)),
            val: e.val,
            name:e.name
          });
        });

        ///-------------------------------------------

        //if(this.recalcule){
         // console.log("calculate")
         // this.recalcule = false;
          let matrix = generateMatrix(points,img_sizex,img_sizey,pixelSize, this.minMaxValue[1], this.minMaxValue[0], this.power, xoffset,yoffset)
          generateImage(this.imageCanvas_, img_sizex, img_sizey,pixelSize,matrix,this.cols,this.alphaColor,this.colorShift)
        //}
        ////////////////////////

         // cria a máscara de contorno na área ---------------------------
        ctx.beginPath();
        geometricArea.forEach((e) => {
          let lastPos = undefined;
          e.forEach((pos) => {
            if (lastPos) {
              // ctx.moveTo(lastPos[0],lastPos[1]);
              ctx.lineTo(pos[0], pos[1]);
            } else {
              ctx.moveTo(pos[0], pos[1]);
            }
            lastPos = pos;
          });
        });
        ctx.closePath();
        ctx.clip();
        //--------------------------------------------------------------
        
        ctx.drawImage(this.imageCanvas_, xoffset, yoffset,img_sizex,img_sizey);
        ctx.restore();

        if(this.showLegends){
            points.forEach((dest) => {
                let value = isNaN(dest.val)?0:dest.val
                let fixed = 2;
                if (value >= 10) fixed = 1;
                if (value >= 100) fixed = 0;
                value = value.toFixed(fixed);

               let fontsize = 12*pixelRatio;
               ctx.font = (fontsize|0) + "px Arial";

                if(this.showNames){
                  ctx.fillStyle="#000"
                  ctx.fillText(dest.name, dest.destpos[0]+1, dest.destpos[1]-14);
                  ctx.fillStyle="#f85"
                  ctx.fillText(dest.name, dest.destpos[0], dest.destpos[1]-15);
                }

                ctx.fillStyle="#000"
                ctx.fillText(value + " mm", dest.destpos[0]+1, dest.destpos[1]+1);
                ctx.fillStyle="#fa3"
                ctx.fillText(value + " mm", dest.destpos[0], dest.destpos[1]);
            });
        }
      }

      this.onEndRender();
      return this.canvas;
    };
  }

  if (ImageCanvas) IdwSource.__proto__ = ImageCanvas;
  IdwSource.prototype = Object.create(ImageCanvas && ImageCanvas.prototype);
  IdwSource.prototype.constructor = IdwSource;

  return IdwSource;
})(ImageCanvas);

export default IdwSource;
