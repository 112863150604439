<template>
  <div>
    <vl-map
      style="height: 400px"
      :load-tiles-while-animating="true"
      :load-tiles-while-interacting="true"
      data-projection="EPSG:4326"
      ref="map"
      @mounted="onMapMounted"
    >
      <vl-view
        ref="view"
        :maxZoom="19"
      ></vl-view>

      <vl-layer-vector :z-index="3">
        <vl-source-vector v-if="coordinates != null && coordinates.length>0" ref="objSource">
          <vl-feature :id="'ds-line-' + dataStream.id">
            <vl-geom-line-string
              :coordinates="coordinates"
            ></vl-geom-line-string>
            <vl-style-box :z-index="3">
              <vl-style-stroke
                :color="[255, 100, 0, 0.7]"
                :width="3"
              ></vl-style-stroke>
            </vl-style-box>
          </vl-feature>

          <vl-feature :id="'ds-point-' + +dataStream.id">
            <vl-geom-point :coordinates="posCoordinate"></vl-geom-point>
            <vl-style-box :z-index="5">
              <vl-style-icon
                :scale="1"
                :anchor="[0.5, 1]"
                :size="[40, 40]"
                :src="require('./assets/feedPoint.png')"
              ></vl-style-icon>
            </vl-style-box>
          </vl-feature>
        </vl-source-vector>
      </vl-layer-vector>

      <vl-layer-tile
        :z-index="1"
        v-for="layer in baseLayers"
        :key="layer.source"
        :id="layer.source"
        :visible="layer.visible"
      >
        <component :is="'vl-source-' + layer.source" v-bind="layer"></component>
      </vl-layer-tile>
    </vl-map>

    <b-container fluid>
      <b-row class="my-1">
        <b-col sm="3">
          <label>{{posTime}}</label
          >
        </b-col>
        <b-col sm="9">
          <b-form-input
            name="areaStartPos"
            v-model="position"
            type="range"
            min="0"
            :max="datapoints.length - 1"
          ></b-form-input>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import map from "@components/map/map";
export default {
  data() {
    return {
      position: 0,
      posCoordinate: [0, 0],
      posTime: undefined,
    };
  },
  mixins: [map],
  props: {
    datapoints: { type: Array, require: true },
    dataStream: Object,
  },

  computed: {
    coordinates() {
      let coordinates = [];
      let datapoints = this.datapoints;

      if (datapoints) {
        // console.log(datapoints);
        datapoints.forEach((e) => {
          coordinates.push([e.v2, e.v1]);
        });
      }
      return coordinates;
    },
  },

  watch: {
    position(v) {
      if(this.coordinates.length>0){
        this.posCoordinate = this.coordinates[v];
        this.posTime = DateTime.fromSeconds(this.datapoints[v].ux).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS);
      }
    },
  },

  mounted() {
    this.position = this.coordinates.length - 1;
    this.posCoordinate = this.coordinates[this.position];
  },
};
</script>

<style></style>
