<template>
  <div class="list-page">
    <div class="list-page-header background" :style="headStyle">
      <b-container :fluid="fluid">
        <slot name="head">
          <div class="d-flex">
            <div>
              <h2>{{ title }}</h2>
            </div>
            <div class="ml-auto">
              <slot name="head-tools"></slot>
            </div>
          </div>

          <small>
                <font-awesome-icon icon="info-circle" />
                {{ subTitle }}
              </small>

        </slot>
      </b-container>
    </div>

    <b-container :fluid="fluid" class="body-container">

      <slot name="container">
        <b-card :no-body="noBody" class="shadow">
          <slot></slot>
        </b-card>
      </slot>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    backgroundImage: String,
    title: String,
    subTitle: String,
    noBody: Boolean,
    fluid: { type: String, require: false, default: "lg" },
  },

  computed: {
    headStyle() {
      return {
        //  backgroundImage: 'url('+this.backgroundImage+')'
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.list-page {


  .list-page-header {
    // small {
    // //    color: white;
    // }

    h2 {
      font-family: "Open Sans", Helvetica, Arial, sans-serif;
      font-size: 24px;
      font-weight: 400;
      line-height: 27px;
      margin: 0;
    }

    padding: 15px 0px;
    height: 105px;
    color                   : $body-color;
    // background-color: rgb(86, 110, 79);
    position: relative;
    display: block;
    // text-shadow             : 2px 2px 2px black;
    // background-attachment: fixed;
    background-position: center top;
    /* Center the image */
    background-repeat: no-repeat;
    /* Do not repeat the image */
    background-size: 100%; //cover;
    // top: -18px;
    // box-shadow              : inset 0px -3px 10px #000;
  }

  .body-container {

    margin-top: -2.5rem;

    @media (max-width: 576px) {
      padding-left: 0;
      padding-right: 0;
    }

    // .card{
    //   min-height: 60vh;
    //   padding-top: 0;
    // }
  }
}

.background {
  //   background-color: #000;
  //   background-color: rgb(160, 158, 158);
  // background: #fafafa;
  background: $card-cap-bg;
  border-bottom: 1px solid $card-border-color;
}
</style>
