<template>
  <div>
    <vl-layer-vector id="draw-panel" :z-index="zIndex">
      <vl-source-vector
        :features.sync="drawnFeatures"
        :ident="sourceIdent"
      ></vl-source-vector>
       <!-- <vl-style-box>
          <vl-style-stroke color="green"></vl-style-stroke>
          <vl-style-fill color="rgba(255,0,0,0.5)"></vl-style-fill>
        </vl-style-box>  -->
    </vl-layer-vector>
    <vl-interaction-modify :source="sourceIdent"></vl-interaction-modify>
    <vl-interaction-draw
      :source="sourceIdent"
      type="Polygon"
    ></vl-interaction-draw>

    <vl-interaction-snap
      v-if="snap === true"
      :source="sourceIdent"
      :priority="10"
    ></vl-interaction-snap>

    <div class="editPanel">
      <b-card>
        <b-button size="sm" variant="outline-danger" @click="clearArea">
          limpar
        </b-button>
        <hr />

        <b-form-checkbox v-model="snap" name="check-button" switch>
          snap
        </b-form-checkbox>
      </b-card>
    </div>
    <!-- {{drawnFeatures}}
    <br>
    {{value}} -->
  </div>
</template>

<script>
import makeId from '@/utils/makeId'

export default {
  data() {
    return {
      drawnFeatures: [],
      snap: true,
      sourceIdent:"",
    };
  },

  props: {
    value: {
      type: Array,
      default: () => {
        [];
      },
    },
    zIndex: { type: Number, default: 3 },
  },

  methods: {
    clearArea() {
      this.drawnFeatures = [];
      this.$emit("input", []);
    },
  },
  beforeMount() {
    this.sourceIdent = "area-source-"+makeId(6);
    let area = JSON.parse(JSON.stringify(this.value));
    this.drawnFeatures = [];

    if (area) {
      area.forEach((e, i) => {
        this.drawnFeatures.push({
          type: "Feature",
          geometry: { type: "Polygon", coordinates: [e] },
          id: "31ac4169-c545-4664-9b7b-b8ba936203e7" + i,
        });
      });
    }

    // console.log("draw feature", this.drawnFeatures);
  },

  watch: {
    drawnFeatures(v) {
      // console.log("update feature", v);

      let geometry = [];
      v.forEach((element) => {
        geometry.push(
          JSON.parse(JSON.stringify(element.geometry.coordinates[0]))
        );
      });
      this.$emit("input", geometry);
    },
  },
};
</script>

<style lang="scss" scoped>
.editPanel {
  max-width: 200px;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  z-index: 3;

  .card-body {
    padding: 0.5rem;
  }
}
</style>
