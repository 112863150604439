<template lang="html">
  <pageTemplate :sensor="sensor">
    <template v-slot:sensor>
      <div class="svg-image water-sensor-Color">
        <wellSvg :width="'100%'" :height="180" :sensor="sensor" />
      </div>
    </template>

    <b-card>
    <template v-slot:info>
      <ul class="sensor-info-group">
        <li>
          <b>Nível atual</b>
          <span> {{ $filters.localeNumber(level,2) }}<small> m </small> </span>
        </li>
        <li>
          <b>Máximo do dia</b>
          <span> {{  $filters.localeNumber(maxDayLevel,2) }}<small> m </small> </span>
        </li>
        <li>
          <b>Mínimo do dia</b>
          <span> {{  $filters.localeNumber(minDayLevel,2) }}<small> m </small> </span>
        </li>
      </ul>
    </template>

    <div class="d-flex flex-wrap mb-2">
      <div>
        <h5>Histórico de nível do poço</h5>
      </div>
      <div class="ml-auto">
        <data-range v-model="dateRange" />
      </div>
    </div>

    <div v-if="loadStatus == 'loaded'">
      <div class="well-chart">
        <wellChart
          :sensorLevel="sensorLevel"
          :maxLevel="maxLevel"
          :data="chartData"
        />

        <wellLevelTable :dailySeries="data.dailySeries" />
      </div>
    </div>
    <c-loading v-else :status="loadStatus"></c-loading>
  </b-card>
  
  </pageTemplate>
</template>

<script>
import { DateTime } from "luxon";

import wellSvg from "./wellSvg";
import wellChart from "./wellChart";
import sensWell from "./sensWell";
import pageTemplate from "../sensorDetailsTemplate";
import wellLevelTable from "./wellLevelTable";

export default {
  extends: sensWell,

  data() {
    return {
      loadStatus: "loading",
      data: [],
      dateRange: null,
    };
  },
  computed: {
    chartData() {
      return this.data;
    },
  },
  mounted() {
    let end = new Date(Date.now());
    end.setHours(0, 0, 0, 0);
    let start = new Date(Date.now());
    start.setHours(start.getHours() - this.sensor.defaultPeriod);
    start.setHours(0, 0, 0, 0);
    this.dateRange = [start, end];
  },

  methods: {
    loadData() {
      this.loadStatus = "loading";
      let start = Math.round(
        DateTime.fromJSDate(this.dateRange[0]).toSeconds()
      );
      let end = Math.round(
        DateTime.fromJSDate(this.dateRange[1])
          .plus({ days: 1 })
          .toSeconds()
      );
      this.$http
        .get(
          "sens/sensor/" +
            this.sensorId +
            "/serie?start=" +
            start +
            "&end=" +
            end
        )
        .then(
          (data) => {
            this.data = data;
            this.loadStatus = "loaded";
          },
          (error) => {
            console.error("load well sensor error:", error);
            this.loadStatus = "error";
          }
        );
    },
  },

  watch: {
    dateRange() {
      this.loadData();
    },
  },

  components: {
    wellSvg,
    wellChart,
    pageTemplate,
    wellLevelTable,
  },
};
</script>

<style lang="scss" scoped>
.well-chart {
  margin: 1rem 0px;
}
</style>
