<script>
import IdwSource from "./IDWSource";
import { source } from "vuelayers/lib/mixin";
/**
 * Um source para layers com mapa de calor
 *
 */
export default {
  name: "vl-source-idw",
  mixins: [source],
  data() {
    return {
      source: undefined,
    };
  },
  props: {
    power: { type: Number, default: 2 },
    pixelSize: { type: Number, default: 4 },
    points: { type: Array, required: true },
    area: { type: Array, required: true },
    minValue:   { type: Number },
    maxValue:   { type: Number },
    legends:    { type: Boolean, required: false, default: false },
    names:      { type: Boolean, required: false, default: false },
    gradient:   { type: Array, required: false },
    alphaColor: { type: Number, required: false },
    colorShift: { type: Number, required: false },
  },
  methods: {
    createSource() {
      this.source = new IdwSource({
        area: this.area,
        points: this.points,
        pixelSize: this.pixelSize,
        power: this.power,
        minValue: this.minValue,
        maxValue: this.maxValue,
        legends: this.legends,
        names: this.names,
        gradient: this.gradient,
        onStartRender: this.startRender,
        onEndRender: this.endRender,
        alphaColor: this.alphaColor,
        colorShift: this.colorShift,
      });
      return this.source;
    },

    async startRender() {
      this.$emit("startrender");
    },

    async endRender() {
      this.$emit("endrender");
    },
  },

  watch: {
    power() {
        this.scheduleRecreate();
    },
    points: {
      deep: true,
      handler() {
          this.scheduleRecreate();
      },
    },
    minValue() {
        this.scheduleRecreate();
    },
    maxValue() {
        this.scheduleRecreate();
      
    },
    pixelSize() {
        this.scheduleRecreate();
      
    },
  },
};
</script>
