<template>
  <div>
    <!-- Nome da cultura -->
    <b-row>
      <ValidationProvider
        slim
        vid="name"
        name="Nome"
        :rules="{ required: true, min: 3 }"
        v-slot="v"
      >
        <b-form-group label="Nome" class="col-sm-7">
          <b-form-input
            name="name"
            v-model="form.name"
            type="text"
            maxlength="30"
            placeholder="nome"
            :state="$validateState(v)"
          ></b-form-input>
          <span class="invalid-input">{{ v.errors[0] }}</span>
        </b-form-group>
      </ValidationProvider>

      <!-- Ícone da cultura -->
      <b-form-group label="ícone" class="col-sm-5">
        <b-input-group>
          <b-input-group-prepend is-text>
            <img  v-if="form.icon" height="24px" :src="require('./assets/48/' + form.icon + '.png')"/> 
          </b-input-group-prepend>
          <b-form-select
          v-model="form.icon"
          name="icon"
          :options="icon_options"
          ></b-form-select>
        </b-input-group>
      </b-form-group>
    </b-row>

    <!-- Descrição da cultura -->
    <ValidationProvider
      slim
      vid="description"
      name="Nome"
      :rules="{ min: 5, max: 250 }"
      v-slot="v"
    >
      <b-form-group id="input-group-description" label="Descrição">
        <b-form-textarea
          id="input-description"
          name="description"
          v-model="form.description"
          placeholder="Descrição"
          maxlength="250"
          :state="$validateState(v)"
        ></b-form-textarea>
        <span class="invalid-input">{{ v.errors[0] }}</span>
      </b-form-group>
    </ValidationProvider>


    <div class="stage-chart" style="height: 220px;">
      <cultureStageChart :stages="form.stages" />
    </div>


    <cultureFases :culture="form" :datePlanting="datePlanting" :currentDay="currentDay"/>

    <b-table-simple stacked="md" small class="stage-table">
      <b-thead class="text-center">
        <b-tr>
          <b-th></b-th>
          <b-th class="title">Nome</b-th>
          <b-th class="number">Dias</b-th>
          <b-th class="number">KC</b-th>
          <b-th class="number">Raiz</b-th>
          <b-th class="number">Area sombreada</b-th>
          <b-th class="number">Fator de segurança</b-th>
          <b-th class="number">Função Ks</b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr v-for="(stage, index) in form.stages" :key="index">
          <b-td stacked-heading="Mover">
            <b-button
              size="sm"
              class="button-move"
              v-if="index != 0"
              title="mover para cima"
              @click="moveUpStage(index)"
              variant="outline-secondary"
              >▲</b-button
            >
          </b-td>
          <b-td stacked-heading="Nome">
            <b-form-input
              size="sm"
              placeholder="Nome"
              v-model="stage.name"
              type="text"
            ></b-form-input>
          </b-td>
          <b-td stacked-heading="Duração">
            <ValidationProvider
              slim
              vid="durationDays"
              name="Duração"
              :rules="{ required: true, minValue: 1 }"
              v-slot="v"
            >
              <b-form-input
                size="sm"
                v-model="stage.durationDays"
                type="number"
                min="1"
                step="1"
                :state="$validateState(v)"
              ></b-form-input>
            </ValidationProvider>
          </b-td>
          <b-td stacked-heading="Kc">
            <ValidationProvider
              slim
              vid="kc"
              name="KC"
              :rules="{ required: true, minValue: 0 }"
              v-slot="v"
            >
              <b-form-input
                size="sm"
                v-model="stage.initialKc"
                type="number"
                min="0"
                max="5"
                step="0.1"
                :state="$validateState(v)"
              ></b-form-input>
            </ValidationProvider>
          </b-td>
          <b-td stacked-heading="Raiz">
            <ValidationProvider
              slim
              vid="root"
              name="Raiz"
              :rules="{ required: true, minValue: 1 }"
              v-slot="v"
            >
              <b-form-input
                size="sm"
                v-model="stage.initialRootDepth"
                type="number"
                min="1"
                step="0.1"
                :state="$validateState(v)"
              ></b-form-input>
            </ValidationProvider>
          </b-td>
          <b-td stacked-heading="Área Sombreada">
            <ValidationProvider
              slim
              vid="initialShadedArea"
              name="Área"
              :rules="{ required: true, minValue: 0 }"
              v-slot="v"
            >
              <b-form-input
                size="sm"
                v-model="stage.initialShadedArea"
                type="number"
                min="0"
                step="0.1"
                :state="$validateState(v)"
              ></b-form-input>
            </ValidationProvider>
          </b-td>
          <b-td stacked-heading="Fator Segurança">
            <ValidationProvider
              slim
              vid="initialShadedArea"
              name="Fator Segurança"
              :rules="{ required: true, minValue: 0, maxValue: 1 }"
              v-slot="v"
            >
              <b-form-input
                size="sm"
                placeholder="Segurança"
                v-model="stage.safetyFactor"
                type="number"
                min="0"
                max="1"
                step="0.1"
                :state="$validateState(v)"
              ></b-form-input>
            </ValidationProvider>
          </b-td>

          <b-td stacked-heading="Ks">
            <b-form-select size="sm" v-model="stage.ksType" :options="ks_options"></b-form-select>
          </b-td>

          <b-td stacked-heading="Apagar estágio">
            <b-button
              size="sm"
              title="remover Estágio"
              variant="danger"
              @click="removeStage(index)"
              >x</b-button
            >
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div class="mt-3">
      <b-button @click="newStage" variant="primary">Nova</b-button>
    </div>

    <!-- <b-button type="submit">Salvar</b-button>
    <b-button type="reset">Cancelar</b-button>-->
  </div>
</template>

<script>
import cultureStageChart from "./cultureStageChart";
import cultureFases from "@irrigcomponents/culture/cultureFaseDates";

export default {
  data() {
    return {
      form: undefined,
      updateOrigin: true,
      icon_options: [
        { value: null, text: "Selecione" },
        { value: "corn", text: "milho" },
        { value: "soy", text: "soja" },
        { value: "cotton", text: "algodão" },
        { value: "coffe", text: "café" },
        { value: "bean", text: "feijão" },
        { value: "wheat", text: "trigo" },
        { value: "barley", text: "cevada" },
        { value: "carrot", text: "cenoura" },
        { value: "sorghum", text: "sorgo" },
        { value: "sunflower", text: "girasol" },
        { value: "tomatoes", text: "tomate" },
        { value: "agave", text: "capim" },
        { value: "sugarCane", text: "cana" },
        { value: "castor", text: "mamona" },
        { value: "millet", text: "milheto" },
        { value: "default", text: "default" },
      ],

      ks_options:[
      { value: "LOG", text: "Logarítmico" },
      { value: "FAO56", text: "FAO56(teste)" },
      { value: "LINEAR", text: "LINEAR" },
      { value: "ONE", text: "KS=1" },
      ]
    };
  },

  props: {
    value: Object,
    cultureId: { type: String, required: false },
    cultureList: Array,
    datePlanting:{type:String, required:false},
    currentDay:{type:Number}
  },

  components: {
    cultureStageChart,
    cultureFases
  },

  beforeMount() {
    this.form = JSON.parse(JSON.stringify(this.value));
  },

  watch: {
    value: {
      deep: true,
      handler(v) {
        this.updateOrigin = false;
        this.form = JSON.parse(JSON.stringify(v));
      },
    },
    form: {
      deep: true,
      handler(v) {
        if (this.updateOrigin) {
          this.$emit("input", JSON.parse(JSON.stringify(v)));
        } else {
          this.updateOrigin = true;
        }
      },
    },
  },

  methods: {
    removeStage(index) {
      this.form.stages.splice(index, 1);
    },

    moveUpStage(index) {
      let arr = this.form.stages;
      arr.splice(index - 1, 0, arr.splice(index, 1)[0]);
    },

    newStage() {
      this.form.stages.push({
        name: "",
        durationDays: "",
        initialKc: "",
        initialRootDepth: "",
        initialShadedArea: "",
        safetyFactor: "",
      });
    },
  },
};
</script>

<style lang="scss" scoped>



.stage-chart {
    // background-color: rgb(240, 240, 240);
    background-color: #fff;
    margin-bottom: 2rem;
    padding: 1rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.stage-table {
  th {
    &.number {
      width: 12%;
      min-width: 5.5rem;
    }

    &.title {
      min-width: 6rem;
    }
  }

  td {
    padding: 0px;
  }

}

.button-move {
  width: 50px;
}

.stage-routules {
  font-size: 11px;
}
</style>
