<template>
  <div>
    <div class="d-flex flex-wrap mb-4">
      <div>
        <h5>Consumo Mensal</h5>
      </div>
      <div class="ml-auto">
        <data-range v-model="dateRange" />
      </div>
    </div>
    <div v-if="loadStatus == 'loaded'">
      <div class="flow-chart">
        <chartMonthy :data="data" :unit="unit" />
        <volumeTable :dailySeries="data" :unit="unit" />
      </div>
    </div>
    <c-loading v-else :status="loadStatus"></c-loading>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import volumeTable from "./volumeTableMonth.vue";
import sensHydro from "./sensHydro";
import chartMonthy from "./chartMonthy.vue"

export default {
  extends: sensHydro,

  data() {
    return {
      loadStatus: "loading",
      data: [],
      dateRange: null,
    };
  },

  computed: {
    chartData() {
      return this.data;
    },
  },
  mounted() {
    let end = new Date(Date.now());
    end.setHours(23, 59, 59, 0);
    let start = new Date(Date.now());
    start.setHours(start.getHours() - 24*365);
    start.setHours(0, 0, 0, 0);
    this.dateRange = [start, end];
  },

  methods: {
    /**
     * Função para loading de dados de volume de cosumo
     */
    loadData() {
      this.loadStatus = "loading";

      let start = DateTime.fromJSDate(this.dateRange[0]).toISO({
        includeOffset: false,
      });
      let end = DateTime.fromJSDate(this.dateRange[1])
        .plus({ days: 1 })
        .toISO({ includeOffset: false });

      this.$http
        .get(
          "sens/hydrometer/" +
            this.sensorId +
            "/report-months?start=" +
            start +
            "&end=" +
            end
        )
        .then(
          (data) => {
            this.data = data;
            this.loadStatus = "loaded";
          },
          (error) => {
            console.error("load hydrometer error:", error);
            this.loadStatus = "error";
          }
        );
    },
  },

  watch: {
    dateRange() {
      this.loadData();
    },
  },

  components: {
    volumeTable,
    chartMonthy
  },
};
</script>

<style></style>
