<template>
  <div>
    <b-row>
      <b-col lg="4" class="mb-3">
        <b-card no-body class="sticky-top">
          <b-row no-gutters>
            <b-col sm="6" lg="12">
              <div class="map-div">
                <simple-map id="detail-eqipment-map" v-if="equipment.type !== undefined" :mapHeight="'220px'">
                  <component :is="'map-' + equipment.type" :equipment="equipment" />
                  <tbody v-if="equipment.fields !== undefined">
                    <fieldMap v-for="field in equipment.fields" :key="field.id" :field="field" />
                  </tbody>
                </simple-map>
              </div>
            </b-col>
            <b-col sm="6" lg="12" class="p-2">

              <div>
                <i>{{ equipment.description }}</i>
              </div>
              <ul class="equipment-info-group">
                <li>
                  <b>Vazão</b>
                  <span>{{ $filters.round(equipment.flowRate, 2) }} m³/h</span>
                </li>
                <li>
                  <b>Potência</b>
                  <span>{{ $filters.round(equipment.powerKw, 2) }} kW</span>
                </li>
                <li>
                  <b>Área Irrigada</b>
                  <span>{{ $filters.round(equipment.irrigatedArea / 10000, 2) }} ha</span>
                </li>
                <li v-if="equipment.completTurn100Percent != undefined">
                  <b>Lâmina a 100%</b>
                  <span>{{ $filters.round(equipment.water100, 2) }} mm</span>
                </li>
                <li v-if="equipment.completTurn100Percent != undefined">
                  <b>Volta a 100%:</b>
                  <span>{{ $filters.decimalhourToTime(equipment.completTurn100Percent, "h ", "m") }}</span>
                </li>
              </ul>

              <div class="mt-5">
                <b-button block variant="primary" size="sm" :to="{
                  name: 'equipmentEdit',
                  params: { id: equipment.id },
                }" v-if="$can('edit', 'Equipment')">
                  <font-awesome-icon icon="edit" /> Editar
                </b-button>

                <b-button block v-if="$can('remove', 'Equipment')" class="ml-auto" size="sm" variant="danger" @click="removeEquipment">
                  <font-awesome-icon icon="trash" /> Excluir </b-button>
              </div>

            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- TELA DE LISTA DE PARCELAS -->
      <b-col>
        <!-- <b-card no-body> -->
        <b-tabs class="tab-card">
          <b-tab title="Parcelas" active>
            <div class="text-center m-5" v-if="numFiels == 0">
              <div>
                <img style="width: 140px;" src="@/assets/field.png" alt="" />
              </div>
              <div class="mt-4">
                <h5>Sem parcelas ativas</h5>
                <p>
                  No momento não existe nenhum parcela ativa neste equipamento.
                </p>
                <b-button v-if="$can('create', 'Field')" :to="{
                  name: 'fieldNew',
                  params: { equipmentId: equipment.id },
                }" variant="primary">Nova parcela
                </b-button>
              </div>
            </div>
            <div v-else>
              <field-card v-for="field in equipment.fields" :key="field.id" :field="field" />

              <div class="d-flex justify-content-center">
                <b-button size="sm" v-if="$can('create', 'Field')" :to="{
                  name: 'fieldNew',
                  params: { equipmentId: equipment.id },
                }" variant="primary">Nova parcela
              </b-button>
            </div>
            </div>
          </b-tab>

          <b-tab title="Histórico">
            <fieldHistorical :equipment="equipment"></fieldHistorical>
          </b-tab>

          <b-tab title="Ficha técnica">
            <component v-if="equipment.type != undefined" :is="'datatsheet-' + equipment.type" :equipment="equipment" />
          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import pivotMap from "./pivotMap";
import dripMap from "./dripMap";
import fieldMap from "../field/fieldMap";
import fieldHistorical from "./fieldHistorical";
import fieldCard from "../field/fieldCard";
import pivotDatasheet from "./pivotDatasheet";

export default {
  data() {
    return {
      historicalFields: [],
      pdfoptions: {
        margin: [12, 6, 4, 6],
        filename: "ficha_tecnica_" + this.equipment.name,
      },
    };
  },
  props: {
    equipment: { type: Object, required: true },
  },
  components: {
    fieldMap,
    fieldHistorical,
    fieldCard,
    "datatsheet-Pivot": pivotDatasheet,
    "map-Pivot": pivotMap,
    "map-Drip": dripMap,
  },

  computed: {
    numFiels() {
      if (this.equipment.fields == undefined) return 0;

      return this.equipment.fields.length;
    },
  },

  /*  metodos */
  methods: {
    /**
     * Remover equipamento
     */
     removeEquipment() {
      this.$deleteConfirm.show(
        "excluir-equipamento",
        {excludeObjects:["Equipamento","Parcelas ativas","Históricos"]}
      ).then((value) => {

        if (value == true) {
          this.$store
            .dispatch("equipments/removeEquipment", this.equipment.id)
            .then(
              () => {
                this.$router.push({ name: "home" });
              },
              (error) => {
                console.error("removeEquipment error", error);
                //alert(error.response.data.message)
                this.$bvToast.toast(
                  "não foi possível remover o equipamento",
                  {
                    title: "error",
                    variant: "danger",
                    solid: true,
                  }
                );
              }
            );
        }

      });
    },
  },
};
</script>

<style lang="scss" scoped>
.equipment-info-group {
  padding-left: 0;
  border-left: 0;
  border-right: 0;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;

  li {
    position: relative;
    display: block;
    padding: 5px 10px;
    margin-bottom: -1px;
    border-bottom: 1px solid #ddd;

    span {
      float: right !important;
      color: #3c8dbc;
    }
  }
}
</style>
