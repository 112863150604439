<template>
  <div class="data-range" v-bind:class="!dataValid ? 'warnig' : ''">
    <!-- <input
      v-model="startDate"
      name="startDate"
      type="date"
      title="Data de início"
    /> -->
    <b-form-datepicker size="sm" boundary="window" right
      :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' }"
      v-model="startDate"
      :disabled="disabled"
      label-no-date-selected="select"
      ></b-form-datepicker>

    <!-- <span> ~ </span> -->
    <b-form-datepicker size="sm" right :disabled="secondDateDisabled||disabled" :min="startDate" boundary="window"
      :date-format-options="{ 'year': 'numeric', 'month': 'numeric', 'day': 'numeric' }"
      v-model="endDate"
      label-no-date-selected="select"
      ></b-form-datepicker>

    <!-- <input
      :disabled="secondDateDisabled"
      v-model="endDate"
      name="endDate"
      type="date"
      :min="startDate"
      title="Data de Fim"
    /> -->
    <button :disabled="!dataValid" v-if="!autoUpdate" @click="updateValue()" class="btn-primary">
      <font-awesome-icon icon="check" />
    </button>
  </div>
</template>

<script>
import { DateTime } from "luxon";

export default {
  name: "data-range",
  data() {
    return {
      startDate: undefined,
      endDate: undefined,
      updateEnable: false,
      dataValid: false,
      maxDays: undefined
    };
  },

  props: {
    value: { type: Array },
    autoUpdate: { type: Boolean, default: true },
    disabled : {type:Boolean, default:false}
  },

  mounted() {
    this.setInputsValue();

    if (!this.autoUpdate) {
      this.updateEnable = true
    }
  },

  computed: {
    secondDateDisabled() {
      return this.startDate == undefined || this.startDate == "";
    },
  },
  methods: {
    async setInputsValue() {
      if (this.value) {
        let a = DateTime.fromJSDate(this.value[0]);
        let b = DateTime.fromJSDate(this.value[1]);
        this.startDate = a.toISODate();
        await this.$nextTick();
        this.endDate = b.toISODate();
        await this.$nextTick();
        this.updateEnable = true
      }
    },

    checkValid() {
      let a = DateTime.fromISO(this.startDate);
      let b = DateTime.fromISO(this.endDate);
      this.dataValid = (a.isValid == true && b.isValid == true && b >= a)
    },

    updateValue() {
      let a = DateTime.fromISO(this.startDate);
      let b = DateTime.fromISO(this.endDate);
      if (a.isValid == true && b.isValid == true && b >= a) {
        if (this.updateEnable) {
          this.$emit("input", [a.toJSDate(), b.toJSDate()]);
        }
      }
    },
  },
  watch: {
    value() {
      this.setInputsValue();
    },
    startDate() {
      if (this.autoUpdate)
        this.endDate = "";

      this.checkValid()
    },
    endDate() {
      if (this.autoUpdate)
        this.updateValue();

      this.checkValid()
    },
  },
};
</script>

<style lang="scss" scoped>
.data-range {
  display: flex !important;
  //padding: 0.05rem 0.15rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: white;
  font-size: 14px;

  width: 280px;

  .b-form-datepicker{
    border: 0;
  }


  &.warnig {
   // background-color: #f00 !important;
  }

  input {
    //min-width: 70px;
    padding: 0;
    border: none;
    background-color: inherit;

    &::-webkit-calendar-picker-indicator {
      //   display: none;
      //   -webkit-appearance: none;
      margin: 1px;
      padding: 0;
    }

    &:focus {
      color: #495057;
      background-color: #fff;
      border-color: #80bdff;
      outline: 0;
      -webkit-box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  }

  button {
    border: 1px solid transparent;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0 0.2rem 0.2rem 0;
    margin: -0.05rem -0.15rem -0.05rem 0.15rem;
  }

  span {
    padding: 0.25rem 0.5rem;
  }
}
</style>
