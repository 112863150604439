<template>
  <div v-if="form !== undefined">
    <b-card no-body v-if="!copying">
      <b-tabs card>
        <validation-observer ref="formObserver">
          <validation-observer v-slot="{ invalid }">
            <b-tab active>
              <template #title>
                <font-awesome-icon
                  class="text-danger"
                  icon="exclamation-triangle"
                  v-if="invalid"
                />
                Parcela
              </template>
              <b-row>
                <b-col lg="5">
                  <b-row>
                    <!-- NOME DA PARCELA -->
                    <ValidationProvider
                      slim
                      vid="name"
                      name="Nome"
                      :rules="{ required: true, min: 3 }"
                      v-slot="v"
                    >
                      <b-form-group label="Nome" class="col-12">
                        <b-form-input
                          name="name"
                          v-model="form.name"
                          type="text"
                          maxlength="20"
                          placeholder="nome"
                          :state="$validateState(v)"
                        ></b-form-input>
                        <span class="invalid-input">{{ v.errors[0] }}</span>
                      </b-form-group>
                    </ValidationProvider>

                    <!-- DESCRIÇÃO DA PARCELA-->
                    <ValidationProvider
                      slim
                      vid="description"
                      name="description"
                      :rules="{ min: 5, max: 250 }"
                      v-slot="v"
                    >
                      <b-form-group
                        class="col-12"
                        id="input-group-description"
                        label="Descrição"
                      >
                        <b-form-textarea
                          name="description"
                          v-model="form.description"
                          placeholder="Descrição"
                          maxlength="250"
                          :state="$validateState(v)"
                        ></b-form-textarea>
                        <span class="invalid-input">{{ v.errors[0] }}</span>
                      </b-form-group>
                    </ValidationProvider>
                  </b-row>
                </b-col>

                <b-col>
                  <b-row>
                    <!-- INCIO DO MANEJO -->
                    <ValidationProvider
                      slim
                      vid="startDate"
                      name="Inicio do Manejo"
                      :rules="{ required: true }"
                      v-slot="v"
                    >
                      <b-form-group label="Início do Manejo" class="col-sm-6">
                          <b-form-input
                            name="startDate"
                            v-model="form.startDate"
                            type="date"
                            :state="$validateState(v)"
                          ></b-form-input>
                        <span class="invalid-input">{{ v.errors[0] }}</span>
                      </b-form-group>
                    </ValidationProvider>

                    <!-- DATA DO PLANTIO-->
                    <ValidationProvider
                      slim
                      vid="datePlanting"
                      name="Data do plantio"
                      :rules="{ required: true }"
                      v-slot="v"
                    >
                      <b-form-group label="Data do plantio" class="col-sm-6">
                          <b-form-input
                            name="datePlanting"
                            v-model="form.datePlanting"
                            type="date"
                            :state="$validateState(v)"
                          ></b-form-input>
                        <span class="invalid-input">{{ v.errors[0] }}</span>
                      </b-form-group>
                    </ValidationProvider>

                    <!-- SELECIONAR ESTAÇÂO -->
                    <b-form-group label="Estação" class="col-sm-6">
                      <b-form-select
                        v-model="form.stationId"
                        :options="selectStationList"
                        name="stationId"
                      ></b-form-select>
                    </b-form-group>

                    <!-- SELECIONAR Pluviometro -->
                    <b-form-group label="Pluviômetro" class="col-sm-6">
                      <b-form-select
                        v-model="form.pluviometerId"
                        :options="selectPluvioList"
                        name="pluviometerId"
                      ></b-form-select>
                    </b-form-group>
                  </b-row>
                </b-col>
              </b-row>
            </b-tab>
          </validation-observer>

          <validation-observer v-slot="{ invalid }">
            <b-tab>
              <template #title>
                <font-awesome-icon
                  class="text-danger"
                  icon="exclamation-triangle"
                  v-if="invalid"
                />
                Solo
              </template>

              <div class="d-flex">
                <div class="ml-auto">
                  <popover-select
                    label="importar"
                    :options="selectSoilList"
                    size="sm"
                    v-on:select-item="importSoilData($event)"
                  />
                </div>
              </div>

              <soilEdit v-model="form.soil" insertExcess/>
            </b-tab>
          </validation-observer>

          <validation-observer v-slot="{ invalid }">
            <b-tab>
              <!-- EDITOR DE CULTURA -->
              <template #title>
                <font-awesome-icon
                  class="text-danger"
                  icon="exclamation-triangle"
                  v-if="invalid"
                />
                Cultura
              </template>

              <div class="d-flex">
                <div class="ml-auto">
                  <popover-select
                    label="importar"
                    :options="selectCultureList"
                    size="sm"
                    v-on:select-item="importCultureData($event)"
                  >
                    <b-form-checkbox
                      v-model="onlyWorkspaceCulture"
                      :value="true"
                      :unchecked-value="false"
                      switches
                    >
                      Apenas Minhas Culturas</b-form-checkbox
                    >
                  </popover-select>
                </div>
              </div>
              <cultureEdit v-model="form.culture" :datePlanting="form.datePlanting"/>
            </b-tab>
          </validation-observer>

          <b-tab lazy title="Mapa">
            <component
              v-if="form.type !== undefined"
              :is="'edit-' + form.type"
              :field.sync="form"
            ></component>
          </b-tab>


          <Can I="read" a="Master">
          <validation-observer v-slot="{ invalid }">
            <b-tab>
              <template #title>
                <font-awesome-icon
                  class="text-danger"
                  icon="exclamation-triangle"
                  v-if="invalid"
                />
                Algoritimo
              </template>
              <b-row>
                    <!-- versao -->
                    <ValidationProvider
                      slim
                      vid="Version"
                      name="Version"
                      :rules="{ required: true }"
                      v-slot="v"
                    >
                      <b-form-group label="Version" class="col-12">
                        <b-form-input
                          v-model="form.version"
                          type="number"
                          :state="$validateState(v)"
                        ></b-form-input>
                        <span class="invalid-input">{{ v.errors[0] }}</span>
                      </b-form-group>
                    </ValidationProvider>
              </b-row>
            </b-tab>
          </validation-observer>
        </Can>


        </validation-observer>
      </b-tabs>
    </b-card>
    <div v-else> Copiando parcela... </div>
    
    <!-- {{ field }} -->
    <div class="d-flex m-2">
      <div class="ml-auto buttons-container">
        <b-button @click="submit" variant="primary">Salvar</b-button>
        <b-button @click="cancel">Cancelar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import pivoEdit from "./fieldPivotEdit";
import areaEdit from "./fieldAreaEdit";
import soilEdit from "@irrigcomponents/soil/soilEdit";
import cultureEdit from "@irrigcomponents/culture/cultureEdit";


export default {
  data() {
    return {
      form: undefined,
      soilList: [],
      cultureList: [],
      stationList: [],
      pluvioList: [],
      onlyWorkspaceCulture: false,
      copying : false
    };
  },
  props: {
    field: { type: Object, required: true },
    cloneId : {type:String, required:false}
  },
  computed: {
    selectCultureList() {
      let ret = [];
      let workspaceId = this.$store.getters["accounts/workspaceId"];
      let filter = this.onlyWorkspaceCulture;
      this.cultureList.forEach((e) => {
        if (filter === false || e[2] === workspaceId) {
          ret.push({ value: e[0], text: e[1] });
        }
      });
      return ret;
    },

    selectSoilList() {
      let list = [];
      this.soilList.forEach(function(v) {
        list.push({ value: v[0], text: v[1] });
      });
      return list;
    },
    selectStationList() {
      let list = [];
      this.stationList.forEach(function(v) {
        list.push(
          { value: v.id, text: ""+(v.distance/1000).toFixed(v.distance<10000?1:0)+" km "
            +(v.elevationDiference>0?"↑":"↓")+  
            +Math.abs(v.elevationDiference).toFixed(0)+"m - " + v.name 
          }
        );


      });
      // list.push({value:'00000000-0000-0000-0000-000000000000',text:'nova estação  '})
      return list;
    },
    selectPluvioList() {
      let list = [];
      this.pluvioList.forEach(function(v) {
        list.push({ value: v[0], text: v[1] });
      });
      list.push({
        value: "00000000-0000-0000-0000-000000000000",
        text: "novo pluviômetro  ",
      });
      return list;
    },
  },
  methods: {
    cancel() {
      this.$router.go(-1);
    },
    submit() {
      this.$refs.formObserver.validate().then((result) => {
        if (!result) {
          this.$bvModal.msgBoxOk("Verifique se todos os dados estão corretos", {
            title: "Erro!",
          });
          return;
        }

        this.$store.dispatch("equipments/saveField", this.form).then(
          () => {
            this.$router.go(-1);
          },
          (error) => {
            this.$refs.formObserver.setErrors(this.$validServer(error));
            this.$bvModal.msgBoxOk(
              "Verifique se todos os dados estão corretos",
              { title: "Erro!" }
            );
          }
        );
      });
    },

    //carrega a cultura da api e atribui à cultura da parcela
    importCultureData(v) {
      this.$http.get("/irrig/culture/" + v).then(
        (data) => {
          this.form.culture = data;
        },
        (error) => {
          console.error("load culture error:", error);
        }
      );
    },

    //carrega o solo da api e atribui ao solo da parcela
    importSoilData(v) {
      this.$http.get("/irrig/soil/" + v).then(
        (data) => {
          this.form.soil = data;
        },
        (error) => {
          console.error("import culture error:", error);
        }
      );
    },


    copyFieldToForm(field){
      let form = {};
      let copy = JSON.parse(JSON.stringify(field));
      Object.entries(copy).forEach(([key, value]) => {
        //não copia nenhum dos campos abaixo listados
        // if (!["area", "days","bbox","lastDay","status","volumeToPercentConstant", "volumeToTimeConstant"].includes(key)) {
        //   form[key] = value;
        // }

        if (["type","equipmentId","version","id",
             "name","startDate","datePlanting","description","stationId","pluviometerId",
             "soil","culture",
              "size","start","externalRadius","geometryArea"].includes(key)) {
          form[key] = value;
        }


      });

      if (form.soil === undefined) {
        form.soil = { layers: [] };
      }
      if (form.culture === undefined) {
        form.culture = { icon: "default", stages: [] };
      }

      this.form = form;
    }

  },
  mounted() {
    //copia os dados do equipamento para a área de dados do formulário
    if (this.field !== undefined) {
      this.copyFieldToForm(this.field)
    }


    //Carrega um modelo de clone
    if(this.cloneId !== undefined){
      this.copying = true
        this.$http.get("/irrig/equipment/field/" + this.cloneId).then(
          (data)=>{
            this.copying = false
            data.id = undefined
            this.copyFieldToForm(data)
          }
      );
    }


    

    //carrega a lista de culturas
    this.$http.get("/irrig/culture/maplist").then(
      (data) => {
        this.cultureList = data;
      },
      (error) => {
        console.error("load culture list error:", error);
      }
    );

    //carrega a lista de Solos
    this.$http.get("/irrig/soil/maplist").then(
      (data) => {
        this.soilList = data;
      },
      (error) => {
        console.error("load soil list error:", error);
      }
    );


    this.$http.get("/irrig/equipment/"+ this.field.equipmentId+"/next-stations").then(
      (data) => {
        this.stationList = data;
      },
      (error) => {
        console.error("load station list error:", error);
      }
    );

    //carrega a lista de Pluviômetros
    this.$http.get("/irrig/pluviometer/maplist").then(
      (data) => {
        this.pluvioList = data;
      },
      (error) => {
        console.error("load pluviometer list error:", error);
      }
    );
  },
  components: {
    soilEdit,
    cultureEdit,
    "edit-FieldPivot": pivoEdit,
    "edit-FieldArea": areaEdit,
  },
};
</script>
