<template>
  <div>

  <json-editor v-model="remoteconfig"></json-editor>


    <hr />
    <div>
      <b-button @click="$emit('cancel')" variant="secondary">
        Cancelar
      </b-button>
      <b-button
        v-if="saveStatus == 'saved'"
        @click="saveConfig()"
        variant="success"
      >
        Salvar
      </b-button>
      <b-button v-else variant="secoundary">
        salvando...
        <font-awesome-icon icon="spinner" spin />
      </b-button>
    </div>

    <!-- {{ saveStatus }} -->
    <!-- {{remoteconfig}} -->
  </div>
</template>

<script>

export default {
  data() {
    return {
      saveStatus: "saved",
      remoteconfig: {},
      opt: {
        mode: "tree",
        modes: ["code", "tree"],
      },
    };
  },

  props: {
    feed: { type: Object, required: true },
  },

 
  mounted() {
    this.remoteconfig = JSON.parse(this.feed.nodeConfig);
  },

  methods: {
    onError() {
      //   console.log(v);
    },

    saveConfig() {
      this.saveStatus = "saving";
      this.$http
        .put("/sens/feed/" + this.feed.id + "/nodeConfig", this.remoteconfig)
        .then(
          () => {
            this.saveStatus = "saved";
            this.ScreenMode = "details";
            this.$bvToast.toast("Configurações do sensor salvas", {
              title: "status",
              variant: "success",
              solid: true,
            });
          },
          (error) => {
            console.log("load feed error:", error);
            this.saveStatus = "error";
            this.$bvToast.toast("As configurações não foram salvas", {
              title: "status",
              variant: "danger",
              solid: true,
            });
          }
        );
    },
  },
};
</script>

