<template>
  <div class="bg-white p-2 mt-4">
    <table-export
      :items="dataPoints"
      :fields="fields"
      :tableName="name"
    >
    </table-export>
  </div>
</template>

<script>
import { DateTime } from "luxon";


const dateTimeFormat = function(value) {
  return DateTime.fromMillis(value).toLocaleString({ month: '2-digit', day: '2-digit', year:'2-digit', hour: '2-digit', minute: '2-digit', hourCycle: 'h23' });
};

const exlDateTimeFormat = function(value) {
  return DateTime.fromMillis(value).toJSDate()
};

var roundFormatter = function(value) {
  if (typeof value !== 'number') return value;
  return value.toLocaleString('pt-br',{minimumFractionDigits:2,maximumFractionDigits:2})
};

export default {
  data() {
    return {
      fields: [
        {
          key: "time",
          label: "Hora",
          sortable: true,
          class: "text-nowrap",
          formatter: dateTimeFormat,
          exlformatter:exlDateTimeFormat
        },
        { key: "value", label: "valor ("+this.unit+")",  sortable: true,formatter: roundFormatter,exlformatter:function(e){return e}},
        // { key: "volume2", label: "Volume2 ("+this.unit+")",  sortable: true,formatter: roundFormatter},
        // { key: "goal",  label: "meta ("+this.unit+")", sortable: false, formatter: roundFormatter},
      ],
    };
  },
  props: {
    data: Array,
    unit:{type :String, default:"cm"},
    name:String
  },
  computed: {

    dataPoints(){
     return this.data.map(e=>{return {
       time:e[0],
       value:e[1]}})
    }

  }
};
</script>
